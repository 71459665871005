@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_colors.scss';

.SurveysSelector {
  padding: 0;
  margin: 0;
  width: 450px;

  > ul {
    padding: 0;
    margin: 18px 0 14px;
    list-style: none;

    > li {
      $selectorHeight: 48px;
      $leftPadding: $selectorHeight;
      $labelLength: 150px;

      position: relative;

      box-sizing: border-box;
      padding: 0 0 0 ($leftPadding + $labelLength);
      margin: 0 0 10px;

      height: $selectorHeight;
      border: solid 1px $c10;

      > svg {
        position: absolute;
        top: 50%;
        left: $leftPadding/2;
        transform: translate(-50%, -50%);

        fill: $c09;
      }

      > label {
        position: absolute;
        top: 50%;
        left: $leftPadding;
        width: $labelLength;
        transform: translateY(-50%);

        font-weight: $fontRegular;
        font-size: 16px;
        color: $c12;

        > small {
          font-size: 14px;
          color: $c09;
        }
      }
    }
  }

  .AutocompleteInput--text {
    line-height: 48px;
    font-size: 16px;
    color: $c02;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: pointer;
  }

  .AutocompleteInput--autocomplete {
    max-height: 370px;
    width: 120%;
  }
}

.SurveysSelector--actions {
  display: flex;
  align-items: center;
}

.SurveysSelector--link {
  box-sizing: border-box;
  display: block;

  flex: 1 1 50%;
  width: 50%;

  height: 45px;
  line-height: 45px;
  text-align: center;
  font-weight: $fontBold;

  background: $c03;
  color: $c01;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background: $c02;
  }
}

.SurveysSelector--reset {
  margin: 0;
  padding: 0 0 0 14px;
  flex: 1 1 50%;
  width: 50%;

  > a {
    font-size: 16px;
    color: $c07;
  }
}

.SurveySelector--warning {
  $triangleHeight: 8px;
  $triangleWidth: 10px;

  position: relative;

  margin: $triangleHeight 0 16px;
  padding: 12px 16px;
  background: $warning;

  &:before {
    content: '';

    position: absolute;
    top: -$triangleHeight;
    left: 16px;
    width: 0;
    height: 0;

    border-left: solid $triangleWidth transparent;
    border-right: solid $triangleWidth transparent;
    border-bottom: solid $triangleHeight $warning;
  }

  > p {
    margin: 0;
    padding: 0 1em 0 0;
    color: $warningText;
    font-weight: $fontRegular;
    font-size: 16px;
  }
}