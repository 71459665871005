@import '../../utils/styles/_colors.scss';

$heatmapAnimationDuration: 0.6s;
$heatmapAnimationEasing: ease-in-out;
$heatmapAnimationTiming: cubic-bezier(0.25,0.1,0.25,1);

$heatmapEmptyColor: $c10;
$heatmapEvenBackground: $c01;
$heatmapRowBorderColor: #EFF1F4;
$heatmapRowHoverBorder: $c11;

$heatmapHeadHeight: 110px;
$heatmapHeadColorDark: $c00;
$heatmapHeadColorLight: $c01;
