
@import '../../utils/styles/_colors.scss';

.SimpleScale {
  display: inline-block;
  position: relative;
  width: 161px;
  height: 9px;
  border-radius: 4px;
  // /tile.gif
  background: url('data:image/gif;base64,R0lGODdhEAASAPEAAOTp7uDl6/z9/f///ywAAAAAEAASAEACMkQgo2Kow05abcL6olsZU9mBH7JdommW2qR66BpuJ0xZdDra2H3PLqPzHXKxBM8lbBUAADs=');
  background-size: 8px 9px;
  overflow: hidden;

  > .SimpleScale--progress {
    box-sizing: border-box;
    margin-left: -1px;
    height: 100%;
    border-right: solid 2px $c08;
    background: $c10;
  }

  &.SimpleScale--highlight-progress > .SimpleScale--progress {
    background: $c09;
    border: none;
  }

  > .SimpleScale--history {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    margin-left: -3px;
    background: linear-gradient(0, transparent 2px, $c08 2px);
    background-size: 1px 4px;
    background-repeat: repeat;
  }

  > .SimpleScale--change {
    box-sizing: border-box;

    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    background: linear-gradient(0, transparent 2px, $c08 2px);
    background-size: 2px 4px;
    background-repeat: repeat-y;
    border-right: solid 2px $c08;

    &.score1 {
      background-color: $score1;
    }

    &.score2 {
      background-color: $score2;
    }

    &.score3 {
      background-color: $score3;
    }

    &.score4 {
      background-color: $score4;
    }

    &.score5 {
      background-color: $score5;
    }
  }

  &.decrease > .SimpleScale--change {
    background-position: right;
    border-right: none;
    border-left: solid 2px $c08;
  }

  &.score1 > .SimpleScale--progress {
    background: $score1;
  }

  &.score2 > .SimpleScale--progress {
    background: $score2;
  }

  &.score3 > .SimpleScale--progress {
    background: $score3;
  }

  &.score4 > .SimpleScale--progress {
    background: $score4;
  }

  &.score5 > .SimpleScale--progress {
    background: $score5;
  }
}

.SimpleScale.full {
  > .SimpleScale--progress {
    border-right: none;
    width: 110% !important;
  }
}

.SimpleScale.empty {
  > .SimpleScale--progress {
    border-right: none;
  }
}