@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_globals.scss';

$footerFontSize: 11px;
$footerColor: $c00;

.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $footerHeight;
  box-shadow: inset 0 1px 0 0 rgba(210,210,210,0.5);
  text-align: center;
  background: $backgroundColor;

  > span {
    line-height: $footerHeight;
    font-variant: $fontRegular;
    font-size: $footerFontSize;
    color: $footerColor;
  }
}