$fillColor: #657488;

.ChangeSymbol {
  display: inline-block;
  text-align: left;
  margin-left: 5px;
  width: 7px;
  height: 7px;
  background-position: center;
  background-size: cover;
  vertical-align: middle;
  position: relative;

  &.rotate {
    transform: rotate(180deg);
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: $fillColor;
  }
}
