@import "../../../../utils/styles/_globals.scss";
@import "../../../../utils/styles/_colors.scss";

$color: #68778a;
$height: 242px;
$borderRadius: 1px;

.CompoundQuestion {
  position: relative;
  padding: 0 30px 10px;
  background: $alternativeBackgroundColor;
  padding: 0 50px 22px;
  > h3 {
    margin: 0;
    padding: 7px 0 7px 0;
    font-size: 14px;
    color: $c08;
  }

  > .CompoundQuestionSection {
    > .SubQuestion {
      display: flex;
      flex-direction: row;
      margin-bottom: 6px;

      > .SubQuestion--text {
        width: 10%;
        flex-basis: 100px;
        flex-grow: 1;
      }

      > .SubQuestion--selections {
        display: flex;
        flex-direction: row;
        width: 90%;

        > div {
          margin-left: 4px;
          margin-right: 4px;
        }
        > .QuestionCard--score {
          margin-right: 0;
          margin-left: 6px;
          font-weight: $fontBold;
          text-align: right;
          flex-shrink: 0;
          width: 38px;
        }
        > .QuestionCard--header {
          flex-basis: content;
          margin-right: 0;
          margin-left: 6px;
          font-weight: $fontBold;
          flex-basis: 16%;
          flex-grow: 1;
          text-align: center;
        }
      }
    }
  }
}
