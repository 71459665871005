@import '../../../../utils/styles/_colors.scss';
@import '../../../../utils/styles/_legend.scss';

.Heatmap-table--surveys {
  @extend %legend;

  > h3 {
    @extend %legend--title;
  }

  .Surveys--label {
    color: $c12;
    margin-right: 5px;
  }
}
