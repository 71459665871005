@import '../../utils/styles/_globals';
@import '../../utils/styles/_legend';

.HighlightProgress {
  @extend %legend;

  > h3 {
    @extend %legend--title;
  }

  .HighlightProgress--toggle {
    display: flex;
    align-items: center;

    > span {
      display: block;
      font-weight: $fontRegular;
      font-size: 14px;
      color: $c12;

      flex: 1 1 auto;
      cursor: pointer;
      user-select: none;
    }

    > .Toggle {
      flex: 0 0;
    }
  }
}