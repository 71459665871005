@import './_globals.scss';
@import './_colors.scss';

html {
  font-size: 14px;
}

* {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  font-size: 14px;
  line-height: 18px;
  font-weight: $fontRegular;
  color: $c02;
}

.AppRoot {
  font-size: 16px;
}


.AppRoot h1 {
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  color: $c08;
  margin: 15px 0 0;

  &::after {
    content: " ";
    display: block;
    margin: 21px 0 0;
    width: 30px;
    height: 4px;
    background-color: #0A1EAA;
  }
}

h1 + .subtitle {
  margin-top: -55px;
  display: block;
}

h2 {
  font-weight: normal;
  font-size: 24px;
  line-height: 18px;
  color: $c08;
  margin-top: 15px;
  margin-bottom: 15px;
}

h3 {
  color: $c00;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}

hr {
  border-top: 1px solid #E4E6E9;
  border-bottom: none;
  margin: 29px -60px 29px -60px;
}

a {
  font-size: 14px;
  line-height: 18px;
  color: #0A1EAA;
  font-weight: normal;
  text-decoration: none;
}

p {
  font-size: 14px;
  line-height: 22px;
  color: #2F363F;
  margin-top: 10px;
}


// section to support mid

// this is an hack to group all styles and apply them only to the mid component,
// without adding any class to the component itself (currently that's not supported)
#root > section {

  h1 {
    font-family: 'Open Sans', sans-serif;
  }

  input {
    font-family: 'Open Sans', sans-serif
  }

  button: {
    font-family: 'Open Sans', sans-serif
  }

}