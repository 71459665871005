@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_globals.scss';

$dropdownContentPadding: 14px;

.Dropdown {
  position: relative;

  > a, > button {
    appearance: none;
    border: none;
    background: none;

    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    padding: 0;

    z-index: $zIndexForeground + 3;

    cursor: pointer;
  }
}

.Dropdown-content {
  $arrowSize: 10px;
  $arrowMargin: 20px;

  position: absolute;
  display: none;
  margin: ($arrowSize + 2px) (-$arrowMargin - $arrowSize) 0 0;
  top: 100%; right: 50%;
  z-index: $zIndexForeground + 1;
  text-align: initial;

  padding: $dropdownContentPadding;
  border-radius: 1px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.21);
  background: $c01;

  min-width: 200px;

  &:before {
    content: '';
    position: absolute;
    top: -$arrowSize;
    right: $arrowMargin;

    width: 0;
    height: 0;
    border-left: $arrowSize solid transparent;
    border-right: $arrowSize solid transparent;

    border-bottom: $arrowSize solid $c01;
  }
}

.Dropdown--tooltip {
  display: none;
  position: absolute;
  top: 100%; right: 0%;
  padding: 8px 14px;
  // transform: translateX(50%);
  z-index: $zIndexForeground + 2;

  background: $backgroundColor;
  border: solid 1px $c10;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.21);

  font-size: 14px;
  font-weight: $fontRegular;
  color: $c09;
  white-space: nowrap;

  .Dropdown:hover & {
    display: block;
  }

  .Dropdown.open:hover & {
    display: none;
  }
}

@keyframes Dropdown-fadein {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.Dropdown.open {
  .Dropdown-content {
    display: block;
    animation: Dropdown-fadein 0.3s ease-in-out;
  }
}