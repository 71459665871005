@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_globals.scss';

.SidebarSearch {
  margin: 0 16px 16px;

  input {
    display: block;
    -webkit-appearance: none;
    box-sizing: border-box;
    padding: .375rem .75rem;
    height: 53px;
    width: 100%;
    border-radius: 26px;

    background-image: none;
    background-clip: padding-box;

    box-shadow: inset 0 2px 3px 0 $c11;
    border: 1px solid rgba(255, 255, 255, 0);

    font-size: 14px;
    line-height: 1.5;
    background-color: $c01;
    color: $c02;

    &:focus, &:hover {
      color: $c03;
    }

    &::placeholder {
      color: $c03;
    }
  }
}

.SidebarCell {
  height: 44px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  a, span {
    font-size: 14px;
    padding: 10px 10px 10px 4px;
    color: $c08;
  }

  .pointer {
    cursor: pointer;
  }

  &:hover {
    a, span {
      color: $c03;
    }
  }

  svg {
    user-select: none;
  }

  & > div {
    height: 44px;
    line-height: 47px;
  }

  &.SidebarCell--disabled {
    a, span {
      color: $c09;
    }
  }

  &.SidebarCell--level0 {
  }

  &.SidebarCell--level1 {
    padding-left: 38px;
  }

  &.SidebarCell--level2 {
    padding-left: 54px;

    .ico-child {
      margin-right: 0;
    }
  }

  &.SidebarCell--level1 + &.SidebarCell--level0 {
    margin-top: 20px;
  }

  &.SidebarCell--level2 + &.SidebarCell--level1 {
    margin-top: 10px;
  }

  &.SidebarCell--selected {
    background: linear-gradient(to right, $c03, $c03 4px, #fff 4px, $backgroundColor 110%);

    .SidebarCell--centerContainer {
      span, a {
        color: $c03;
        font-weight: $fontBold;
      }
    }
  }

  &.SidebarCell--medium {
    .SidebarCell--centerContainer {
      span, a {
        font-weight: $fontMedium;
      }
    }
  }

  .SidebarCell--leftContainer {
    width: 50px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
  }

  .SidebarCell--rightContainer {
    min-width: 36px;
    &:empty {
      min-width: auto;
    }

    line-height: 44px;
    svg {
      vertical-align: middle;
    }
  }

  .SidebarCell--centerContainer {
    align-items: center;
    display: flex;
    width: auto;

    margin-right: auto;
    float: left;
    overflow: hidden;
    white-space: nowrap;

    span, a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ico-home {
    stroke: $iconColor2;
    display: flex;
    margin-right: 14px;
  }

  .ico-child {
    fill: $iconColor2;
    margin-top: -8px;
    margin-right: 16px;
  }

  .ico-child-dot {
    fill: $iconColor2;
    margin-top: -8px;
    margin-right: 8px;
  }

  .ico-child-dot-only {
    fill: $iconColor2;
    margin-top: 2px;
    margin-right: 16px;
  }

  .SidebarCell--expandContainer {
    padding-top: 6px;
  }

  .ico-child + .SidebarCell--expandContainer {
    margin-left: -16px;
    .ico-back {
      margin-right: 12px;
    }
  }

  .ico-back {
    stroke: $iconColor1;
    margin-right: 14px;

    transition-property: all;
    transition-duration: .35s;
  }

  .expanded > svg {
    transform: rotate(-90deg);
  }

  .collapsed > svg {
    transform: rotate(-180deg);
  }
}