@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_colors.scss';


.AutocompleteInput {
  position: relative;

  width: 100%;
  height: 100%;
}

.AutocompleteInput--autocomplete {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $zIndexOverlay;

  > ul {
    margin: 0;
    padding: 0;

    list-style: none;

    > li {
      margin: 0;
      padding: 0;

      cursor: pointer;
    }
  }
}

.AutocompleteInput--default {

  .AutocompleteInput--autocomplete {
    margin: 2px;
    padding-bottom: 18px;
    border: solid 1px $c10;
    box-shadow: 0 2px 10px 0 $c10; // rgba(116,125,137,0.21);
    background: $c01;

    overflow-x: hidden;
    overflow-y: auto;

    min-width: 100%;

    > ul > li {
      margin: 2px 0;
      padding: 0 18px;
      height: 34px;
      line-height: 34px;

      color: $c08;
      font-size: 16px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: $c02;
      }

      &.selected {
        color: $c09;
        cursor: default;
      }

      &.disabled {
        color: $c09;
        cursor: default;
      }
    }
  }

  .AutocompleteInput--search {
    padding: 0 18px;

    &:before {
      display: none;
    }
  }

  .AutocompleteInput--input {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    margin: 18px auto 15px;
    padding: 0 0 9px;
    width: 100%;

    border: 0;
    border-bottom: solid 2px $c03;

    font-size: 16px;
    color: $c03;

    &::placeholder {
      opacity: 1;
      color: $c03;
    }

    &:focus {
      outline: none;
    }
  }
}