%table-simple {
  border-collapse: collapse;
  width: 100%;

  td, th {
    padding: 12px;
    border-top: 1px solid #e9ecef;

    text-align: left;
  }

  .grey td {
    background-color: #f5f5f5;
  }
}