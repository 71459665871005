@import '~basscss/css/basscss.css';
@import './utils/styles/_globals.scss';
@import './utils/styles/_colors.scss';
@import './utils/styles/_foundation.scss';
@import './utils/styles/_buttons.scss';
@import './utils/styles/_layout.scss';
@import './utils/styles/_forms.scss';

body, html {
  margin: 0;
  padding: 0;
}

.black {
  color: $c00;
}

.App-sidebar {
  background: $c10;
}

.App-relativeContainer {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  padding-bottom: $footerHeight;
  background-color: $backgroundColor;
}

.Sidebar-toolbar {
  position: fixed;
  bottom: 0px;
}
