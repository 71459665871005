@import '../../utils/styles/_colors';
@import '../../utils/styles/_globals';
@import '../../utils/styles/_legend';

.RespondentsScene {
  width: 100%;
  border-spacing: 0px;
  border-collapse: separate;

  th {
    font-size: 14px;
    font-weight: 400;
    color: #747D89;

    border-bottom: 1px solid #E8E9EC;
    padding: 15px 25px;
  }

  tbody td {
    border-bottom: 1px solid #E8E9EC;
  }

  .RespondentsScene--bubble-container {
    text-align: center;
  }

  .RespondentsScene--bubble {
    display: inline-block;
    position: relative;

    min-width: 25px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    vertical-align: middle;
    border-radius: 50%;

    text-align: center;
    font-size: 14px;
    margin-right: 5px;

    &.high {
      background-color: $c10;
      color: $c08;
    }

    &.low {
      background-color: $c07;
      color: $c01;
    }
  }

  .warning-badge {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 5px;
    height: 5px;
    background-color: $c07;
    border-radius: 5px;
  }
}

.RespondentsScene--TypeLegend {
  @extend %legend;

  > h3 {
    @extend %legend--title;
  }
}

.RespondentsScene--FilterLegend {
  @extend %legend;

  > h3 {
    @extend %legend--title;
  }

  label {
    display: block;
    clear: both;
    min-height: 25px;
    margin: 0 0 10px 0;

    cursor: pointer;

    font-size: 14px;
    color: $c12;

    > .Toggle {
      float: right;
      margin-top: -3px;
    }
  }
}

.RespondentsScene--Spacer {
  margin: 0;
}

.RespondentsScene--Search {
  margin-top: 1px;

  & input {
    background: url("data:image/svg+xml,%3csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cuse xlink:href='%23path0_fill' fill='%230D1BA8'/%3e%3cdefs%3e%3cpath id='path0_fill' d='M14.997 17l-5.233-5.232a6.336 6.336 0 0 1-3.389.982 6.375 6.375 0 1 1 0-12.75 6.375 6.375 0 0 1 6.375 6.375 6.34 6.34 0 0 1-.982 3.39L17 14.996 14.997 17zm-8.622-5.667a4.964 4.964 0 0 0 4.958-4.958 4.964 4.964 0 0 0-4.958-4.958 4.964 4.964 0 0 0-4.958 4.958 4.964 4.964 0 0 0 4.958 4.958z'/%3e%3c/defs%3e%3c/svg%3e");
    background-position: right;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    box-sizing: border-box;

    color: $c03;
    border-radius: 0px;
    border-width: 0 0 1px 0;
    border-color: $c03;
    font-size: 14px;

    &:focus {
      background: none;
    }

    &::placeholder {
      color: $c03;
    }
  }
}

.RespondentsScene--actions {
  margin: 3px 0 0;
  height: 34px;
  display: flex;

  .RespondentsScene--actions-left {
    flex: 1 1 auto;
  }

  .RespondentsScene--actions-right {
    flex: 0 0 210px;
  }
}

.RespondentsScene--ReturnLink {
  line-height: 34px;

  > svg {
    display: inline-block;
    margin-right: 1em;
    height: 8px;
    width: 6px;
    stroke: currentColor;
  }
}
