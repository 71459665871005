@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_layout.scss';
@import '../../utils/styles/_colors.scss';
@import './_constants.scss';

$HeatmapSidebarWidth: 25%;
$HeatmapContentWidth: 85%;
$HeatmapSidebarMinWidth: 250px;

.Heatmap .Heatmap--Sticky {
  @include content-width($inheritLayout: true);

  position: fixed;
  top: 49px;
  left: 0;
  right: 0;
  background-color: $backgroundColor;
  z-index: $zIndexMain + 1;
  width: 100%;
  padding: 0;
}

.Heatmap--Sticky + .Heatmap--Body {
  margin-top: 246px;
}

.Heatmap--Sticky.Heatmap--benchmark + .Heatmap--Body {
  margin-top: 296px;
}

.Heatmap--Head, .Heatmap--Body {
  display: flex;
  flex-direction: row;
}

.Heatmap--Body {
  @include content-width($noMargin: true, $inheritLayout: true);
}

.Heatmap--Head {
  width: auto;
  max-width: 100%;

  .Cell span {
    font-weight: $fontBold;
    font-size: 15px;
  }
}

.Heatmap--Teams {
  flex: 1 0;
  flex-basis: auto;
  width: $HeatmapSidebarWidth;
  min-width: $HeatmapSidebarMinWidth;
}

.Heatmap--Cells {
  flex: 1 1;
  flex-basis: $HeatmapContentWidth;
  overflow-x: auto;
  overflow-y: hidden;
}

.Heatmap--Head .Heatmap--Cells {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Heatmap--Cells .Animation-height:nth-child(even) > div,
.Heatmap--Teams .Animation-height:nth-child(even) > div {
  background: $c01;
  border-color: $heatmapRowBorderColor;
  border-style: solid;
}

.Heatmap--benchmark {
  .Heatmap--Cells .Animation-height:nth-child(odd) > div,
  .Heatmap--Teams .Animation-height:nth-child(odd) > div {
    background: $c01;
    border-color: $heatmapRowBorderColor;
    border-style: solid;
  }

  .Heatmap--Cells .Animation-height:nth-child(even) > div,
  .Heatmap--Teams .Animation-height:nth-child(even) > div {
    &.hover {
      background: $c01;
    }

    background: none;
    border-color: initial;
    border-style: none;
  }
}

.Heatmap--Cells, .Heatmap--Teams {
  .Animation-height > .Heatmap--row--benchmark {
    background: $selectedRow !important;
  }
}

.Heatmap--Cells .Animation-height:nth-child(even) > div {
  border-width: 1px 1px 1px 0px;
}

.Heatmap--Teams .Animation-height:nth-child(even) > div {
  border-width: 1px 0 1px 1px;
}

.Heatmap--TeamRow {
  height: 44px;
  padding: 2px 0 2px 0;
}

.Heatmap--row {
  position: absolute;
  top: 0;
  left: 0;
}

.Heatmap--actions {
  padding: 35px 0;
  display: flex;
  justify-content: center;

  > div {
    width: 276px;
    height: 44px;
    line-height: 44px;
    cursor: pointer;

    flex: 0 0 276px;

    background-color: $c03;
    text-align: center;
    color: $c01;
    font-weight: 14px;
    font-weight: $fontBold;

    &:hover {
      background-color: $c02;
    }
  }
}