
@import '../../utils/styles/_globals';
@import '../../utils/styles/_colors';

.ProgressIndicator {
  white-space: nowrap;
  line-height: 38px;
  height: 38px;

  .ProgressScore {
    margin: 0 20px 0 0;
    vertical-align: middle;
  }

  .SimpleScale {
    vertical-align: middle;
    width: 120px;
  }

  .ProgressIndicator--label {
    margin: 0 0 0 18px;
    vertical-align: middle;

    font-weight: $fontRegular;
    font-size: 14px;
    color: $c12;
  }
}