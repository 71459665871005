
@import '../../../../utils/styles/_colors.scss';

.SelectionScale {
  display: inline-block;
  position: relative;
  flex-basis: 16%;
  margin-top: 4px;
  flex-grow: 1;
  height: 9px;
  background: url('data:image/gif;base64,R0lGODdhEAASAPEAAOTp7uDl6/z9/f///ywAAAAAEAASAEACMkQgo2Kow05abcL6olsZU9mBH7JdommW2qR66BpuJ0xZdDra2H3PLqPzHXKxBM8lbBUAADs=');
  background-size: 8px 9px;
  overflow: hidden;
  border: solid 1px $iconColor1;

  > .SelectionScale--progress {
    box-sizing: border-box;
    height: 100%;
    border-right: solid 2px $c08;
    background-color: $c12;
  }

  &.SelectionScale--highlight-progress > .SelectionScale--progress {
    background: $c09;
    border: none;
  }
}

.SelectionScale.full {
  > .SelectionScale--progress {
    border-right: none;
  }
}

.SelectionScale.empty {
  > .SelectionScale--progress {
    border-right: none;
  }
}