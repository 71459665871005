@import '../../../../utils/styles/_globals';
@import '../../../../utils/styles/_colors';

$AxisDropdownMaxHeight: 444px;
$AxisDropdownBackHeight: 44px;

.AxisLegend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  color: $c03;
  height: 47px;

  .AxisLegend--axis {
    margin: 0 15px;
    cursor: pointer;

    .AxisLegend--axisTitle {
      color: $c09;
      font-size: 16px;
      margin-right: 5px;
    }

    .AxisLegend--axisValue {
      font-size: 14px;
    }
  }
}

.AxisLegend--reference {
  margin: 0 15px;
  user-select: none;

  label {
    margin: 0 0.5em 0 0;
    vertical-align: middle;

    font-size: 14px;
    cursor: pointer;
    color: $c09;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .Toggle {
    vertical-align: middle;
  }
}

.AxisLegend--dropdown {
  background-color: $c01;
  position: absolute;
  top: 45px;
  z-index: $zIndexOverlay;
  border: 1px solid rgba(116,125,137,0.20);
  box-shadow: 0 2px 10px 0 rgba(116,125,137,0.21);
  border-radius: 1px;

  &.AxisLegend--dropdown-x, &.AxisLegend--dropdown-y {
    max-width: 340px;
    max-height: $AxisDropdownMaxHeight;
    overflow: scroll;
  }

  &.AxisLegend--dropdown-x {
    right: 200px;
  }

  &.AxisLegend--dropdown-y {
    right: 85px;
  }

  &.AxisLegend--dropdown-group {
    width: 190px;
    right: 5px;
  }

  &.AxisLegend--dropdown-benchmark {
    padding: 16px 0 0;
    width: 210px;
    right: 550px;
  }

  .SidebarSearch input {
    border-bottom: solid 2px $c03;
    border-radius: 0;
    box-shadow: none;
    font-size: 16px;
  }
}

.AxisLegend--list {
  list-style: none;

  margin: 4px 0;
  padding: 0;

  > li {
    display: flex;
    position: relative;
    padding: 0 30px 0 1em;
    $height: 44px;
    height: $height;
    line-height: $height;

    border-left: solid 4px transparent;

    color: $c12;
    cursor: pointer;

    > span {
      flex: 0 0 auto;
    }

    > .AxisLegend--surveyName {
      margin: 0 0 0 0.5em;
      flex: 1 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 14px;
      color: $c09;
    }

    &:hover {
      color: $c03;
    }

    &.selected {
      color: $c03;
      border-left-color: $c03;
    }

    > svg {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotate(180deg);

      stroke: $c09;
    }
  }
}

.AxisLegend--back {
  position: relative;
  padding: 0 0 0 30px;

  $height: $AxisDropdownBackHeight;
  height: $height;
  line-height: $height;

  border-bottom: solid 1px $c10;

  cursor: pointer;
  color: $c03;

  &:hover {
    color: $c02;
  }

  > svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    stroke: $c09;
  }
}

.AxisLegend--container {
  height: $AxisDropdownMaxHeight - AxisDropdownBackHeight;
  max-height: $AxisDropdownMaxHeight - AxisDropdownBackHeight;
  overflow: auto;

  .GroupsList {
    padding: 0;
  }
}

.GroupByList {
  padding: 16px 0;
}