@import '../../../../utils/styles/_colors.scss';
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_legend.scss';

.ResultsLegend {
  box-sizing: border-box;
  width: 100%;
  @extend %legend;
  padding-top: 36px;

  > h3 {
    @extend %legend--title;
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;

    > li {
      padding: 0;
    }
  }

  .ResultsLegend--legend {
    > li {
      display: flex;
      align-items: center;
      margin: 0 0 8px;

      > i {
        flex: 0 0 10px;
        display: flex;
        width: 10px;
        height: 10px;
        margin: 2px 17px 0 0;
      }

      > span {
        display: block;
        flex: 1 1 auto;
        color: $c02;
        font-size: 14px;
        font-weight: $fontRegular;
      }

      &.clickable {
        cursor: pointer;

        &:hover > span {
          color: $c03;
        }
      }

      &.disabled {
        > span {
          color: $c09;
        }

        > i {
          opacity: 0.2;
        }
      }

      > div {
        flex: 0 0 25px;
        margin: 0 0 0 17px;
        width: 26px;
        height: 26px;

        font-size: 14px;
        line-height: 26px;
        text-align: center;

        border-radius: 4px;

        font-weight: $fontMedium;
        color: $c00;
        background: $c10;

        > svg {
          vertical-align: middle;
          fill: $c09;
        }

        &.scoreNa {
          background: none;
        }

        &.scoreNotAsked {
           @include not-asked-stripes;
        }

        &.score1 {
          background: $score1;
          color: $score1Text;
        }

        &.score2 {
          background: $score2;
          color: $score2Text;
        }

        &.score3 {
          background: $score3;
          color: $score3Text;
        }

        &.score4 {
          background: $score4;
          color: $score4Text;
        }

        &.score5 {
          background: $score5;
          color: $score5Text;
        }
      }
    }
  }

  .ResultsLegend--display {
    > li {
      display: list-item;
      margin: 0 0 22px;

      > span {
        display: block;
        margin: 0 0 5px 0;
        font-size: 14px;
        color: $c08;

        > label {
          cursor: pointer;
        }

        .Radio {
          margin-right: 0.5em;
        }
      }

      > label {
        display: block;
        font-size: 14px;
        color: $c12;
        line-height: 22px;

        &:after {
          content: '';
          display: inline-block;
          height: 0;
          width: 25px;
          border: none;
          border-top: dashed 2px $c08;
          vertical-align: middle;
          margin: 0 0 0 1em;
        }
      }

      > .Toggle {
        float: left;
        margin-right: 7px;
      }

      &:first-child > label:after {
        border-top: solid 2px $c08;
      }
    }
  }

  .outcome-total {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;

      width: 10px;
      height: 10px;

      background: $varB2;
    }

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;

      width: 10px;
      height: 10px;

      background: $varB3;
    }
  }

  .outcome0 {
    background-color: $varB1;
  }

  .outcome1 {
    background-color: $varB2;
  }

  .outcome2 {
    background-color: $varB3;
  }

  .outcome3 {
    background-color: $varB4;
  }

  .outcome4 {
    background-color: $varB5;
  }

  .outcome5 {
    background-color: $varB6;
  }

  .outcome-overflow {
    background-color: $varB0;
  }

  .maturity-total {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;

      width: 10px;
      height: 10px;

      background: $varA2;
    }

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;

      width: 10px;
      height: 10px;

      background: $varA4;
    }
  }

  .maturity0 {
    background-color: $varA1;
  }

  .maturity1 {
    background-color: $varA2;
  }

  .maturity2 {
    background-color: $varA3;
  }

  .maturity3 {
    background-color: $varA4;
  }

  .maturity4 {
    background-color: $varA5;
  }

  .maturity5 {
    background-color: $varA6;
  }

  .maturity-overflow {
    background-color: $varA0;
  }

  hr {
    margin: 10px 0 12px;
  }

  .ResultsLegend--legend {

    .scoreNa, .scoreNotAsked {
      margin: 0 17px 0 0;
    }
  }
}