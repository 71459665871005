
@import '../../utils/styles/_globals';
@import '../../utils/styles/_colors';

.ProgressScore {
  display: inline-table;
  height: 38px;

  // resets
  border-spacing: 0;
  line-height: normal;
}

.ProgressScore--history, .ProgressScore--score {
  display: table-cell;

  width: 22px;

  font-size: 14px;
  color: $c12;

  text-align: center;
  vertical-align: middle;

  > svg {
    display: inline-block;
    margin: 0 0 0 3px;
    vertical-align: middle;

    fill: $iconColor2;
  }
}

.ProgressScore--history {
  padding: 0 4px 0 0;
}

.ProgressScore--score {
  padding: 0 0 0 4px;
}

.ProgressScore--progress {
  display: table-cell;

  vertical-align: middle;

  > svg {
    margin: 0 auto;
    display: block;
  }
}

.ProgressScore--progress {
  $size: 38px;
  display: table-cell;

  height: $size;
  width: $size;
  border-radius: 4px;
  font-size: 13px;
  font-weight: $fontRegular;

  text-align: center;
  vertical-align: middle;

  background: $c10;
  fill: currentColor;

  > svg {
    margin: 0 auto;
    display: block;
  }

  &.score1 {
    color: $score1Text;
    background-color: $score1;
  }

  &.score2 {
    color: $score2Text;
    background-color: $score2;
  }

  &.score3 {
    color: $score3Text;
    background-color: $score3;
  }

  &.score4 {
    color: $score4Text;
    background-color: $score4;
  }

  &.score5 {
    color: $score5Text;
    background-color: $score5;
  }

  &.scoreNa {
    fill: none;
    stroke: $iconColor2;
  }
}