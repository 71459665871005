$headerHeight: 50px;
$headerMenuHeight: 49px;
$footerHeight: 50px;
$footerMargin: 20px;

$fontLight: 300;
$fontRegular: 400;
$fontMedium: 500;
$fontBold: 700;

$sidebarWidth: 300px;
$sidebarWidthSmall: 225px;

$contentMaxWidth: 1024px;
$contentMargin: 19px;

$mediumScreenLimit: 10000px;
$smallScreenLimit: 1100px;

$sidebarDockedAt: $smallScreenLimit;
$resultsSidebarDockedAt: 1580px * 0.75;

// this is the strategy with zIndexes:
// zIndexMain should be used for arrange main content,
// zIndexForeground for elements of the main content that needs to appear above others
// i.e. dropdowns, menu etc
// zIndexOverlay is for elements that need to be above everything else
// i.e. popups, dialog
// use with zIndex{type} + 1, zIndex{type} + 2...
$zIndexMain: 1;
$zIndexForeground: 100;
$zIndexOverlay: 1000;
