@import '../../utils/styles/_mixins.scss';
@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_colors.scss';

$headerFontSize: 14px;
$headerLinkWidth: 100px;
$headerLinkColor: $c03;
$headerActiveLinkColor: $c02;
$headerPadding: $contentMargin;
$headerBackgroundColor: $c01;

$headerMenuIconSize: 40px;
$headerMenuIconMargin: ($headerHeight - $headerMenuIconSize)/2;

$hoverBackgroundColor: #F1F1FD;

.Header {
  position: relative;
  height: $headerHeight;
  background-color: $headerBackgroundColor;

  // move this above error overlays
  z-index: $zIndexForeground + 1;

  & > nav {
    height: $headerHeight;
    display: flex;
    justify-content: center;

    > a {
      display: block;
      flex: 0 0 $headerLinkWidth;
      height: $headerHeight;
      line-height: $headerHeight;
      width: $headerLinkWidth;

      font-weight: normal;
      font-size: $headerFontSize;
      text-align: center;
      color: $headerLinkColor;
      transition: color 300ms;

      border-bottom: solid 2px transparent;

      &:hover, &.active {
        color: $headerActiveLinkColor;
      }

      &:hover {
        background: rgba($hoverBackgroundColor, 0.5);
      }

      &.active {
        border-color: $headerLinkColor;
      }
    }
  }
}

.Header--logo {
  position: absolute;
  top: 0;
  left: $headerPadding;
  height: 100%;
}

.Header--profile {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: $headerPadding - $headerMenuIconMargin;
  height: 100%;
  padding: 0 0 0 40px;

  justify-content: flex-end;

  z-index: $zIndexMain + 2;

  @media (max-width: 950px) {
    padding: 0;
  }
}

.Header--tenant {
  flex: 1 1 auto;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  line-height: $headerHeight;
  font-size: 14px;
  color: $c03;
  text-align: right;

  cursor: pointer;

  @media (max-width: 950px) {
    display: none;
  }
}

.Header--menu {
  display: inline-block;
  flex: 0 0 $headerHeight;

  height: $headerHeight;
  width: $headerHeight;

  .Dropdown-content {
    min-width: 300px;
  }
}

.Header--menu-icon {
  position: relative;

  margin: $headerMenuIconMargin;
  height: $headerMenuIconSize;
  width: $headerMenuIconSize;
  line-height: $headerMenuIconSize;

  cursor: pointer;

  text-align: center;
  font-size: 14px;
  font-weight: $fontMedium;

  border-radius: 50%;
  background: $c10;
  color: $c03;

  user-select: none;

  transition: background 0.3s ease-in-out,
              color 0.3s ease-in-out;

  &:hover {
    background: $c03;
    color: $c01;
  }

  > svg {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);

    fill: currentColor;

    &.ico-share {
      margin-left: -1px;
    }
  }
}

.Header--menu-links {
  list-style: none;
  padding: 6px 8px;
  margin: 0;

  > li {
    > a, > button {
      // reset button
      appearance: none;
      border: none;
      background: none;
      width: 100%;
      box-sizing: border-box;
      text-align: left;

      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      padding: 0 14px 0 0;

      height: 48px;
      line-height: 48px;

      color: $c02;
      font-size: 16px;
      font-weight: $fontRegular;
      cursor: pointer;

      &:hover {
        color: $c03;
      }

      &.alert {
        color: $c07;

        &:hover {
          color: darken($c07, 10);
        }
      }
    }
  }
}
