@import '../../utils/styles/_globals';
@import '../../utils/styles/_colors';
@import '../../utils/styles/_legend';

.ResultsDetails {
  margin: 18px 0 0;
}

.TeamProfile--legend {
  padding-top: 16px;
  @extend %legend;

  h3 {
    @extend %legend--title;
  }

  .RadioSelect ul label {
    color: $c12;
  }
}
