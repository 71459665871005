@import '../../_constants.scss';
@import '../../../../utils/styles/_globals.scss';

.TeamHead {
  cursor: pointer;
  height: 48px;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 104%;

  &.hover {
    box-shadow: 1px 2px 11px -6px $c03;
    border-color: #EFF1F4;

    border-top-color: $heatmapRowHoverBorder;
    border-bottom-color: $heatmapRowHoverBorder;
    background: $heatmapEvenBackground;
      transition:
        box-shadow 0.3s ease-in-out,
        border-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
  }
}

.TeamHead--link {
  margin-right: 5px;
  visibility: hidden;
}

.Heatmap--row {
  &.hover .TeamHead--link {
    visibility: visible;
  }
}
