@import '../../../../utils/styles/_colors.scss';

.SortIcon {
  // reset button
  appearance: none;
  background: none;
  border: none;
  outline: none;

  display: inline-block;
  vertical-align: middle;

  margin: 0 0 4px 0.5em;
  height: 13px;
  width: 19px;

  cursor: pointer;

  fill: $c03;

  &:hover,
  &:focus {
    fill: $c02;
  }

  &.asc {
    vertical-align: initial;
    > svg {
      transform: scaleY(-1);
    }
  }
}