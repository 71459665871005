
@import '../../../utils/styles/_globals';
@import '../../../utils/styles/_colors';

$importFromDropColor: #F4F6F9;

.Form--fieldset.ImportForm--calculation {
  display: grid;
  margin: 38px 0 0;

  grid-template-columns: 300px 300px 300px;
  grid-template-rows: auto 40px;

  > caption, > .Form--caption {
    grid-column: 1 / 4;

    > span {
      margin: 0 0 0 13px;
      color: $c03;
      cursor: pointer;

      &:hover {
        color: $c02;
      }
    }
  }

  > label {
    font-size: 16px;
    color: $c03;
    cursor: pointer;
    line-height: 40px;
    font-weight: $fontRegular;

    &:hover {
      color: $c02;
    }

    & > span {
      margin: 0 0 0 16px;
    }
  }
}

.ImportForm--details {
  margin: 20px 0 0;
  padding: 20px 64px 32px;

  > table {
    width: 100%;

    th, tr {
      text-align: left;
      font-weight: $fontRegular;
    }

    th {
      font-size: 14px;
      color: $c12;
      line-height: 24px;
    }

    td {
      font-size: 16px;
      color: $c08;
      line-height: 24px;
    }
  }
}

.Form--fieldset.ImportForm--format {
  margin: 40px 0 25px;
  display: grid;

  grid-template-columns: 150px 300px;
  grid-template-rows: auto 40px;

  > caption, > .Form--caption {
    grid-column: 1 / 3;
  }

  > label {
    font-size: 16px;
    color: $c03;
    cursor: pointer;
    line-height: 40px;
    font-weight: $fontRegular;

    &:hover {
      color: $c02;
    }

    & > span {
      margin: 0 0 0 16px;
    }
  }
}

.ImportForm--fileupload {
  box-sizing: border-box;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;

  border-radius: 6px;
  background-color: $importFromDropColor;

  background-image: repeating-linear-gradient(to right,
    transparent,
    transparent 10px,
    $c09 10px,
    $c09 18px),
  repeating-linear-gradient(to bottom,
    transparent,
    transparent 10px,
    $c09 10px,
    $c09 18px),
  repeating-linear-gradient(to right,
    transparent,
    transparent 10px,
    $c09 10px,
    $c09 18px),
  repeating-linear-gradient(to bottom,
    transparent,
    transparent 10px,
    $c09 10px,
    $c09 18px);

  background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: 0 0, 0 0, 0 100%, 100% 0;

  &.dragover {
    background-image: repeating-linear-gradient(to right,
      transparent,
      transparent 10px,
      $c08 10px,
      $c08 18px),
    repeating-linear-gradient(to bottom,
      transparent,
      transparent 10px,
      $c08 10px,
      $c08 18px),
    repeating-linear-gradient(to right,
      transparent,
      transparent 10px,
      $c08 10px,
      $c08 18px),
    repeating-linear-gradient(to bottom,
      transparent,
      transparent 10px,
      $c08 10px,
      $c08 18px);
  }

  > span {
    font-size: 16px;
    font-weight: $fontRegular;
    color: $c03;
  }

  > input {
    display: none;
  }

  &.dragover, &:hover {
    > span {
      color: $c02;
    }
  }
}

.Form--fieldset.ImportForm--lone-upload {
  margin-top: 20px;
}