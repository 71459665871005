@import '../../utils/styles/_colors.scss';

.Comparison--Tick {
  margin-right: 15px;
  cursor: pointer;
  color: $c10;

  svg path {
    fill: currentColor;
  }

  &.color0 { color: $chartColor1; }
  &.color1 { color: $chartColor2; }
  &.color2 { color: $chartColor3; }
  &.color3 { color: $chartColor4; }
  &.color4 { color: $chartColor5; }
  &.color5 { color: $chartColor6; }
  &.color6 { color: $chartColor7; }
  &.color7 { color: $chartColor8; }
  &.color8 { color: $chartColor9; }
  &.color9 { color: $chartColor10; }
  &.color10 { color: $chartColor11; }
  &.color11 { color: $chartColor12; }
}

.Comparison--noTeams {
  position: absolute;

  margin: 0 0 0 20px;
  padding: 10px;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);

  color: $c08;

  max-width: 400px;
  text-align: center;
}
