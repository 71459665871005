@import '../../utils/styles/_legend.scss';

.ThresholdsLegend {
  @extend %legend;

  > h3 {
    @extend %legend--title;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;

    > li {
      position: relative;
      padding: 0 0 0 28px;
      margin: 0;
      font-size: 14px;
      font-weight: $fontRegular;

      height: 42px;
      line-height: 42px;

      .ThresholdsLegend--label {
        color: $c12;
        float: left;
      }

      .ThresholdsLegend--values {
        float: right;
        font-weight: $fontBold;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin: -6px 0 0;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        background: $c10;
      }

      &.score1:before {
        background: $score1;
      }

      &.score2:before {
        background: $score2;
      }

      &.score3:before {
        background: $score3;
      }

      &.score4:before {
        background: $score4;
      }

      &.score5:before {
        background: $score5;
      }
    }
  }
}
