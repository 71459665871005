@import '../../utils/styles/globals';

@keyframes errorSlideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ErrorView {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  margin-top: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(249, 250, 252, 0.8);
  z-index: $zIndexForeground;
  animation: 1s ease-out 0s 1 errorSlideInFromTop;

  .ErrorView--container {
    flex-direction: column;
    margin-top: -49px;
  }

  .ErrorView--infinity {
    margin-left: auto;
    margin-right: auto;
  }

  .ErrorView--heading {
    font-size: 24px;
    line-height: 28px;
  }

  .ErrorView--subject, .ErrorView--subject a {
    font-size: 18px;
    line-height: 21px;
  }
}

.ErrorView--full {
  margin-top: 0;
}