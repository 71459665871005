@import '../../../../utils/styles/_colors';
@import '../../../../utils/styles/_globals';

.DotsChart {
  $svgTopMargin: 16px;
  $svgPadding: 25px;
  $svgPaddingLeft: 40px;
  $axisLabelSize: 20px;

  $totalSvgLeftPadding: ($svgPaddingLeft + $axisLabelSize);
  $totalSvgBottomPadding: ($svgPadding + $axisLabelSize + 10px);

  position: relative;

  .DotsChart--yLabel {
    position: absolute;
    margin: -$svgPadding 0 0 0;
    top: 50%;
    left: 0;
    width: $axisLabelSize;

    text-align: left;

    > div {
      position: absolute;
      font-size: 14px;
      color: $c03;
      font-weight: $fontBold;

      white-space: nowrap;

      transform-origin: 0 0;
      transform: rotate(-90deg) translateX(-50%);
    }
  }

  .DotsChart--xLabel {
    position: absolute;
    padding: 0 $svgPadding 0 $totalSvgLeftPadding;
    bottom: 0;
    left: 0;
    right: 0;
    height: $axisLabelSize;

    text-align: center;
    font-size: 14px;
    color: $c03;
    font-weight: $fontBold;
  }

  > svg {
    margin: $svgTopMargin 0 0;
    padding: $svgPadding $svgPadding $totalSvgBottomPadding $totalSvgLeftPadding;
    overflow: visible;

    .background rect {
      fill: $scatterPlotBackgroundColor;
    }

    .xGrid path, .yGrid path {
      stroke: $backgroundColor;
    }

    g.tick line {
      stroke: $backgroundColor;
      stroke-width: 1px;
    }

    g.axis path {
      stroke: $c09;
    }

    .xReferenceGrid {
      path {
        visibility: hidden;
      }

      .tick line {
        stroke: $backgroundColor;
        stroke-width: 8px;
      }
    }

    .yReferenceGrid {
      path {
        visibility: hidden;
      }

      .tick line {
        stroke: $backgroundColor;
        stroke-width: 8px;
      }
    }
  }

  .horizontal-grid, .vertical-grid {
    stroke: $c09;
    stroke-width: 0.5px;
    stroke-dasharray: 2px, 4px;
  }

  .labels rect, .labels circle {
    fill-opacity: 0;
  }

  .labels text {
    font-size: 14px;
    color: $scatterPlotLabelColor;
  }

  .overlay.grab {
    cursor: grab;
  }

  .label.no-overlap.not-selected {
    opacity: 0.5 !important;
  }

  .Dot--Symbol {
    &.item {
      cursor: pointer;
      fill: $scatterPlotDotColorFill;
    }

    &.color0 { fill: $chartColor1; }
    &.color1 { fill: $chartColor2; }
    &.color2 { fill: $chartColor3; }
    &.color3 { fill: $chartColor4; }
    &.color4 { fill: $chartColor5; }
    &.color5 { fill: $chartColor6; }
    &.color6 { fill: $chartColor7; }
    &.color7 { fill: $chartColor8; }
    &.color8 { fill: $chartColor9; }
    &.color9 { fill: $chartColor10; }
    &.color10 { fill: $chartColor11; }
    &.color11 { fill: $chartColor12; }

    &.selected {
      stroke-width: 6px;
      fill: $c03;
      stroke: $c01;
    }

    &.not-selected {
      opacity: 0.5 !important;
    }
  }

  .thresholdColor1 {
    stroke: $score1;
  }

  .thresholdColor2 {
    stroke: $score2;
  }

  .thresholdColor3 {
    stroke: $score3;
  }

  .thresholdColor4 {
    stroke: $score4;
  }

  .thresholdColor5 {
    stroke: $score5;
  }

  %DotsChart--Icons {
    position: absolute;
    background: rgba(255, 255, 255, 0.66);
    border-radius: 6px;
    cursor: pointer;

    > div {
      width: 34px;
      height: 34px;
    }

    .selected {
      background: $c03;
      border-radius: 6px;

      svg path {
        fill: $c01;
      }
    }
  }

  .DotsChart--ZoomIcons {
    @extend %DotsChart--Icons;

    width: 34px;
    right: $svgPadding + 15px;
    bottom: $totalSvgBottomPadding + 15px;

    div + div {
      border-top: 1px solid #EDEDED;
    }
  }

  .DotsChart--ScaleIcons {
    @extend %DotsChart--Icons;

    width: 34px;
    right: $svgPadding + 15px;
    bottom: $totalSvgBottomPadding + 15px + 34px * 2 + 15px;
  }

  .DotsChart--CursorIcons {
    @extend %DotsChart--Icons;

    width: 34px;
    right: $svgPadding + 15px;
    top: $svgPadding + 15px;
  }

  #labelTooltip {
    display: none;
    position: absolute;
    margin: 6px 0 0;
    padding: 8px 14px;
    top: 0;
    left: 0;

    max-width: 50%;
    border: solid 1px $c10;

    background: $backgroundColor;
    color: $c09;
    font-weight: $fontRegular;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.21);

    transform: translateX(-50%);

    &.visible {
      display: block;
    }
  }
}
