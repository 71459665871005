
@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_globals.scss';

.Nav {
  $containerHeight: 47px;
  $height: 38px;
  $width: 136px;
  $margin: 12px;

  box-sizing: border-box;
  margin: ($containerHeight - $height)/2 16px 0 auto;
  padding: 0;
  height: $height;

  display: flex;
  float: right;

  border: solid 1px $c10;
  border-radius: $height/2;

  > a, > span, > button {
    // reset button appearance
    appearance: none;
    border: none;
    background: none;

    display: block;
    height: $height - 2;
    line-height: $height - 2;

    flex: 0 0 136px;
    width: $width;
    margin-left: $margin;
    text-align: center;

    font-size: 14px;
    color: $c03;
    font-weight: $fontRegular;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $c02;
    }

    &.selected {
      color: $c01;
      background: $c03;
      border-radius: $height - 2;
      cursor: default;
    }

    > svg {
      fill: currentColor;
      margin: 0 8px 2px -8px;
      vertical-align: middle;
    }
  }
}