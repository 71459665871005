@import '../../../../utils/styles/_globals';
@import '../../../../utils/styles/_colors';

.TeamTooltip {
  width: 364px;
  background: $c01;
  border-radius: 1px;
  box-shadow: 0 2px 56px 0 rgba(116,125,137,0.21);
  border: 1px solid rgba(116,125,137,0.2);

  position: absolute;
  top: 150px;
  right: 20px;
  z-index: $zIndexForeground;

  h2 {
    font-size: 28px;
    line-height: 28px;
    font-weight: $fontMedium;
    margin: 0;
  }

  .TeamTooltip--body {
    margin-top: 20px;
  }

  .TeamTooltip--tiles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 154px;
    margin-top: -70px;
  }

  .TeamTooltip--buttonContainer {
    margin: 30px 0;
  }

  .TeamTooltip--close {
    cursor: pointer;
    float: right;

    svg {
      padding: 0;
    }
  }

  .TeamTooltip--head {
    padding: 50px 33px;
    min-height: 80px;
    background-color: $c10;
  }

  .TeamTooltip--category {
    border: 2px solid $c10;
    border-radius: 4px;
    margin: 15px 33px 0 33px;
    box-shadow: 0 2px 0 0 $c10, inset -8px -78px 0 0 $c01;

    display: flex;
    flex-direction: row;

    .TeamTooltip--icon {
      width: 60px;
      height: 60px;
      background-color: $c10;

      svg {
        padding: 0;
      }

      svg path {
        fill: $c09;
      }
    }

    .TeamTooltip--name {
      display: flex;
      align-items: center;
      padding: 20px;
      flex-grow: 1;
    }

    .TeamTooltip--score {
      display: flex;
      align-items: center;
      padding: 20px;
    }
  }

  .TeamTooltip--teamList {
    border-bottom: 1px solid $c10;
    margin-top: 15px;
    margin-bottom: 50px;

    .TeamTooltip--team {
      border-top: 1px solid $c10;
    }
  }
}