@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_layout.scss';
@import '../../utils/styles/_colors.scss';

@keyframes rotate {
  from {transform: rotate(360deg);}
  to {transform: rotate(0deg);}
}

@keyframes alertLongRequestSlideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.AlertLongRequest--container {
  @include content-width;
}

.AlertLongRequest {
  font-size: 14px;
  margin: 18px auto;
  padding: 18px;
  border: 1px solid $c01;
  border-radius: 8px;
  background-color: $c10;
  box-shadow: inset 0 2px 0 0 $c11;
  animation: 1s ease-out 0s 1 alertLongRequestSlideInFromTop;

  .AlertLongRequest--Close {
    margin-top: 4px;
  }

  .AlertLongRequest--Spinner {
    vertical-align: middle;
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
