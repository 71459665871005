@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';


.TenantMenu--blocks {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 420px;
  max-width: 450px;

  > li {
    $blockHeight: 48px;
    $leftPadding: $blockHeight;
    $labelLength: 105px;

    position: relative;

    box-sizing: border-box;
    padding: 0 0 0 ($leftPadding + $labelLength);
    margin: 0 0 10px;

    height: $blockHeight;
    border: solid 1px $c10;

    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &.inactive {
      border-color: transparent;
    }

    > svg {
      position: absolute;
      top: 50%;
      left: $leftPadding/2;
      transform: translate(-50%, -50%);

      fill: $c09;
    }

    > label {
      position: absolute;
      top: 0;
      left: $leftPadding;
      width: $labelLength;
      height: $blockHeight;
      line-height: $blockHeight;

      font-weight: $fontRegular;
      font-size: 16px;
      color: $c12;
    }

    > .content {
      display: block;
      flex: 1 1 auto;

      font-weight: $fontRegular;
      font-size: 16px;
      color: $c02;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.metadata {
        position: relative;

        white-space: normal;
        padding: 0 $leftPadding 0 0;

        cursor: pointer;

        > svg.ico-parent {
          position: absolute;
          top: 50%;
          right: $leftPadding/2;
          transform: translate(50%, -50%) rotate(180deg);

          stroke: $c09;
        }

        > svg.ico-right-arrow {
          fill: $c09;
          margin: 0 0.5em 4px;
          vertical-align: middle;
        }
      }
    }

    .AutocompleteInput--text {
      height: $blockHeight;
      line-height: $blockHeight;

      font-size: 16px;
      font-weight: $fontRegular;
      color: $c02;
      cursor:  pointer;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis
    }

    .AutocompleteInput--autocomplete {
      max-height: 370px;
      width: 120%;
    }
  }
}

.TenantMenu--back {
  $backHeight: 30px;
  $leftPadding: $backHeight;

  position: relative;
  margin: -14px -14px 0 -14px;
  padding: 0 0 0 $leftPadding;
  height: $backHeight;
  line-height: $backHeight;

  font-size: 14px;
  font-weight: $fontRegular;
  color: $c02;
  cursor: pointer;

  border-bottom: solid 1px $c10;

  > svg {
    position: absolute;
    top: 50%;
    left: $leftPadding/2;

    transform: translate(-50%, -50%);

    stroke: $c09;
  }
}