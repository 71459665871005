@import '../../../../utils/styles/_colors.scss';
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_legend.scss';

.RespondentsLegend {
  box-sizing: border-box;
  width: 100%;
  @extend %legend;

  > h3 {
    @extend %legend--title;
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;

    > li {
      display: flex;
      padding: 0;
      margin: 0 0 8px;
      align-items: center;

      > span {
        display: block;
        flex: 1 1 auto;
        color: $c02;
        font-size: 14px;
        font-weight: $fontRegular;
      }

      > div {
        flex: 0 0 25px;
        margin: 0 17px 0 0;
        width: 25px;
        height: 25px;

        font-size: 14px;
        line-height: 25px;
        text-align: center;

        border-radius: 50%;
        background: $c10;

        &.bubbleLow {
          background: $c07;
          color: $c01;
        }

        &.bubbleWarning {
          position: relative;
          background: $c10;
          color: $c08;

          &:after {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            background: $c07;

            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}