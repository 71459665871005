@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';

$selectedCardColor: $selectColor;
$hoverColor: $c09;

.Card {
  border: 2px solid $c10;
  box-shadow: inset -8px -78px 0 0 $c01, 0 2px 0 0 $c11;
  border-radius: 4px;
  min-width: 600px;
  color: $c00;

  &.Card:hover {
    cursor: pointer;
    border: 2px solid $hoverColor;
  }

  &.Card--selected, &.Card--selected:hover {
    border: 2px solid $selectedCardColor;
  }

  &.Card--expanded {
    .main {
      .ico-child + .ico-back {
        transform: rotate(270deg);
      }
    }
  }

  &.Subgroup--expanded {
    .ico-child + .ico-back {
      transform: rotate(270deg);
    }
  }
}

.Card--score-value, .Card--label {
  display: inline-block;
  margin: 0 10px;

  font-weight: $fontBold;
  font-size: 14px;
}

.Card--score-value {
  width: 22px;
}

.Card--tick {
  display: inline-block;
  cursor: pointer;
}

.Card--main {
  $padding: 20px;
  $selectorsWidth: 68px;
  $titleWidth: 25%;
  $linkWidth: 100px;
  $height: 60px;

  box-sizing: border-box;
  position: relative;
  display: table;
  width: 100%;
  padding: 0 $padding;
  height: 60px;
  background: $c01;
  border-radius: 4px;

  .Card--selectors {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    width: $selectorsWidth;

    .ico-child {
      position: absolute;
      fill: $iconColor1;

      left: 35px;
      top: $height / 2;
      margin: -10px 0 0 0;
    }

    .ico-back {
      position: absolute;
      stroke: $iconColor1;

      left: 42px;
      top: $height / 2;
      margin: -6px 0 0 0;
      transform: rotate(180deg);
    }
  }

  .Card--title {
    display: table-cell;
    vertical-align: middle;
    width: $titleWidth;
  }

  .Card--score {
    position: absolute;
    margin-left: $selectorsWidth + $padding;
    left: $titleWidth;
    right: $linkWidth;
    top: 50%;

    transform: translateY(-50%);
  }

  .Card--link {
    display: table-cell;
    vertical-align: middle;

    margin: 0 0 0 auto;
    width: $linkWidth;
    text-align: right;
  }
}

.Subgroup {
  padding: 0 20px 0 40px;
}