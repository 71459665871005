
@import '../../../utils/styles/_globals.scss';
@import '../../../utils/styles/_colors.scss';
@import '../../../utils/styles/_tables.scss';

.SurveyList {
  > h1 {
    padding: 16px 0;
  }

  > table {
    @extend %table-simple;

    margin-bottom: 19px;

    a {
      margin: 0 8px;
    }
  }

  .SurveyList--survey {
    $surveyPadding: 16px;
    $surveyLineHeight: 30px;

    position: relative;
    background: $c01;
    border: 2px solid $c10;
    margin: 10px 0;
    padding: $surveyPadding;

    > h1 {
      margin: 0 1em 0 0;
      padding: 0;
      display: inline-block;
      line-height: $surveyLineHeight;

      font-size: 20px;
      font-weight: $fontMedium;
      color: $c00;

      &:after {
        display: none;
      }
    }

    > h2 {
      margin: 0;
      padding: 0;
      display: inline-block;
      line-height: $surveyLineHeight;

      font-size: 18px;
      font-weight: $fontRegular;
      color: $c00;
    }

    > nav {
      position: absolute;
      top: $surveyPadding;
      right: $surveyPadding;

      > button, > a {
        appearance: none;
        border: 0;
        margin: 0;

        display: inline-block;
        width: 100px;
        text-align: center;

        font-size: 14px;
        line-height: $surveyLineHeight;
        color: $c03;
        cursor: pointer;

        &:hover {
          color: $c02;
        }

      }
    }

    > table {
      margin: 25px 0 20px;
      width: 100%;

      tr {
        td, th {
          vertical-align: top;
          height: 30px;

          > button, > a {
            appearance: none;
            background: none;
            border: none;

            display: inline;
            color: $c03;
            cursor: pointer;
            font-size: 14px;

            &:hover {
              color: $c02;
            }
          }
        }

        th {
          font-weight: $fontBold;
          text-align: left;
        }

        td {
          text-align: center;
        }

        td:nth-child(3) {
          text-align: left;

          > ul {
            margin: 0 0 20px;
            padding: 0 0 0 20px;
          }
        }

        td:nth-child(4) {
          text-align: left;
        }

        td:nth-child(5) {
          text-align: left;
        }

        td:nth-child(6) {
          text-align: left;
        }

        td:nth-last-child(2) {
          text-align: center;
        }
      }

      tr.failed {
        td {
          color: $c07;
        }
      }

      tr.active {
        td {
          font-weight: $fontMedium;
        }
      }
    }

    > footer {
      margin: 20px 0 0;

      > a {
        display: inline-block;
        padding: 11px 1em;

        font-size: 14px;
        font-weight: $fontBold;
        color: $c01;
        background: $c03;
        cursor: pointer;

        &:hover {
          background: $c02;
        }
      }
    }
  }
}