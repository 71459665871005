@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_layout.scss';

$menuFontSize: 14px;
$menuLineColor: $c11;
$menuSelectedColor: $c02;
$menuTextColor: $c03;
$menuItemWidth: 100px;
$menuLeftMargin: $contentMargin;
$menuSidebarFontSize: 16px;
$menuBackgroundColor: $backgroundColor;

.HeaderMenu--wrapper {
  height: $headerMenuHeight;
  width: 100%;
}

.HeaderMenu {
  display: flex;
  box-sizing: border-box;
  padding: 0 19px;
  height: $headerMenuHeight;
  width: 100%;

  border-top: 2px solid $menuLineColor;
  border-bottom: 1px solid $c11;
  background-color: $menuBackgroundColor;

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;

    z-index: $zIndexOverlay;
  }
}

.HeaderMenu--Icon {
  flex: 0 0 28px;
  width: 28px;
  height: 47px;
}

.HeaderMenu--Content {
  flex: 1 1 auto;
}