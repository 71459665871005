@import '../../../../utils/styles/_colors.scss';
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_legend.scss';

.HeatmapLegend {
  box-sizing: border-box;
  width: 100%;
  @extend %legend;

  > h3 {
    @extend %legend--title;
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;

    > li {
      display: flex;
      padding: 0;
      margin: 0 0 8px;
      align-items: center;

      > span {
        display: block;
        flex: 1 1 auto;
        color: $c02;
        font-size: 14px;
        font-weight: $fontRegular;
      }

      > div {
        flex: 0 0 25px;
        margin: 0 17px 0 0;
        width: 26px;
        height: 26px;

        font-size: 14px;
        line-height: 26px;
        text-align: center;

        border-radius: 4px;
        background: $c10;

        > svg {
          vertical-align: middle;
          fill: $c09;
        }

        &.scoreNotAsked {
          @include not-asked-stripes;
        }
      }
    }
  }
}