@import './_globals.scss';

@mixin media-medium-content {
  @media (min-width: $smallScreenLimit) and (max-width: $mediumScreenLimit) {
    @content;
  }
}

@mixin media-small-content {
  @media (max-width: $smallScreenLimit) {
    @content;
  }
}


@mixin media-sidebar-docked {
  @media (min-width: $sidebarDockedAt) {
    @content;
  }
}

@mixin content-width ($noMargin: false, $inheritLayout: false) {
  margin-left: auto;
  margin-right: auto;
  max-width: $contentMaxWidth;

  $letfLayoutAlways: "&.left-layout-always";
  $letfLayout: "&.left-layout";
  $rightLayout: "&.right-layout";
  $fullLayout: "&.full-layout";

  @if $inheritLayout {
    $letfLayoutAlways: ".left-layout-always &";
    $letfLayout: ".left-layout &";
    $rightLayout: ".right-layout &";
    $fullLayout: ".full-layout &";
  }

  #{$letfLayoutAlways} {
    @if $noMargin == false {
      box-sizing: border-box;
      max-width: 100%;
      padding-left: $contentMargin;
      padding-right: $sidebarWidth + 10;

      &.expand-right {
        transition: padding 0.3s ease-in-out;
        padding-right: $contentMargin;
      }
    }
    @else {
      max-width: none;
      width: auto;
    }
  }

  @include media-medium-content {
    max-width: none;
    width: auto;

    @if $noMargin == false {
      box-sizing: border-box;
      max-width: 100%;
      padding-left: $sidebarWidthSmall + 10;
      padding-right: $sidebarWidthSmall + 10;

      #{$letfLayout} {
        padding-left: $contentMargin;

        &.expand-right {
          transition: padding 0.3s ease-in-out;
          padding-right: $contentMargin;
        }
      }

      #{$letfLayoutAlways} {
        padding-left: $contentMargin;
      }

      #{$rightLayout} {
        padding-right: $contentMargin;
      }

      #{$fullLayout} {
        box-sizing: content-box;
        max-width: $contentMaxWidth;
        padding: $contentMargin;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @include media-small-content {
    @if $noMargin == false {
      box-sizing: border-box;
      padding-right: $contentMargin;
      padding-left: $contentMargin;
    }

    max-width: 100%;

    margin-left: auto;
    margin-right: auto;
  }
}