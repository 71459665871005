
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_layout.scss';
@import '../../../../utils/styles/_colors.scss';

.LegendsIcon {
  position: relative;
  width: 100%;
  height: 100%;

  color: $c03;
  cursor: pointer;

  &:hover {
    color: $c02;
  }

  svg {
    position: absolute;
    fill: currentColor;

    &:first-child {
      margin-top: -8px;
      top: 50%;
      left: 8px;
    }

    &:last-child {
      transition: transform 200ms ease-in-out;
      margin-top: -11px;
      top: 50%;
      left: 20px;
    }
  }

  &.isOpen > svg:last-child {
    transform: rotate(90deg);
  }
}

@include media-sidebar-docked {
  .LegendsIcon {
    > svg:last-child {
      transform: rotate(90deg);
    }

    &.isOpen > svg:last-child {
      transform: rotate(0);
    }
  }
}