@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_globals.scss';

$homeBreakpoint: 1600px;

.Results--charts {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.Results--group {
  box-sizing: border-box;
  padding: 0 15px;
  flex: 0 1 auto;
  width: 50%;
  max-width: 700px;

  cursor: pointer;

  > h2 {
    display: inline;
    text-align: left;

    font-size: 21px;
    font-weight: $fontRegular;
    text-align: center;
  }

  > h3 {
    display: inline;
    margin: 0 0 0 12px;
    font-size: 14px;
    color: $c12;
    text-align: center;
  }

  &:hover {
    > h2 {
      color: $c02;
    }
  }
}


.Results--descriptions {
  > h3 {
    padding: 0 15px;
    color: $c00;
  }

  > div {
    display: flex;
    justify-content: center;
  }

  &.single > h3 {
    text-align: center;
  }
}

.Results--description {
  flex: 0 1 50%;
  width: 50%;
  padding: 0 15px;

  color: $c08;
  font-size: 14px;
  line-height: 22px;
}