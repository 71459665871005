
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';

.Results--scores {
  $border: $c09;
  position: relative;
  display: flex;
  padding: 30px 0 33px;
  border-bottom: solid 1px $border;
  max-width: 700px;

  &:before {
    content: '';

    position: absolute;
    bottom: 0;
    left: 30px;

    border-bottom: solid 10px $border;
    border-left: solid 13px transparent;
    border-right: solid 13px transparent;
  }

  &:after {
    content: '';

    position: absolute;
    bottom: -1px;
    left: 30px;

    border-bottom: solid 10px $backgroundColor;
    border-left: solid 13px transparent;
    border-right: solid 13px transparent;
  }
}


.Results--score {
  flex: 0 1 33%;
  width: 33%;
  $height: 35px;

  > div {
    display: inline-block;
    width: $height;
    height: $height;
    vertical-align: middle;

    line-height: $height;
    text-align: center;
    font-weight: $fontMedium;

    background: $c10;
    border-radius: 5px;

    transition: color .2s,
                background-color .2s;

    > svg {
      fill: $iconColor2;
      vertical-align: middle;
    }

    &.score1 {
      background: $score1;
      color: $score1Text;
    }

    &.score2 {
      background: $score2;
      color: $score2Text;
    }

    &.score3 {
      background: $score3;
      color: $score3Text;
    }

    &.score4 {
      background: $score4;
      color: $score4Text;
    }

    &.score5 {
      background: $score5;
      color: $score5Text;
    }

    &.scoreNotAsked {
      cursor: not-allowed;
      @include not-asked-stripes;
    }
  }

  > label {
    margin: 0 0 0 0.5em;
    line-height: $height;
    vertical-align: middle;

    font-size: 14px;
    color: $c12;
  }

  &.Results--score--highlighted > label {
    font-weight: $fontBold;
  }

  &.Results--score--clickable {
    cursor: pointer;

    > label {
      cursor: pointer;
    }
  }
}