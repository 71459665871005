@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';

$groupTitleColor: $c08;

%Group--icon {
  margin-right: 10px;
}

.Group {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 61px;
  margin-top: 30px;
  margin-bottom: 10px;

  > svg {
    @extend %Group--icon;

    rect {
      fill: $c11;
    }

    path {
      fill: $c09;
    }
  }

  span {
    font-size: 16px;
    line-height: 16px;
  }

  .Group--title {
    color: $groupTitleColor;
    font-weight: $fontMedium;
  }

  .Group--score {
    margin-left: 20px;
    margin-right: 20px;

    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    font-weight: $fontMedium;

    color: $c01;
    border-radius: 4px;

    &.Group--score1 {
      background-color: $score1;
      color: $score1Text;
    }

    &.Group--score2 {
      background-color: $score2;
      color: $score2Text;
    }

    &.Group--score3 {
      background-color: $score3;
      color: $score3Text;
    }

    &.Group--score4 {
      background-color: $score4;
      color: $score4Text;
    }

    &.Group--score5 {
      background-color: $score5;
      color: $score5Text;
    }
  }

  .ProgressIndicator {
    margin: 0 30px 0 20px;
  }
}

.Group--icon-placeholder {
  @extend %Group--icon;

  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;

  color: $c09;
  background: $c11;

  font-weight: $fontMedium;
  font-size: 24px;

  user-select: none;
}