@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';


.Question--main {
  $padding: 10px;
  $selectorsWidth: 34px;
  $titleWidth: 25%;
  $linkWidth: 100px;
  $expandWidth: 40px;
  $extraMargin: 44px;

  box-sizing: border-box;
  position: relative;
  display: table;
  width: 100%;
  padding: 0 $padding;
  height: 42px;
  background: $c01;

  .Question--selectors {
    display: table-cell;
    vertical-align: middle;
    width: $selectorsWidth;
  }

  .Question--title {
    display: table-cell;
    vertical-align: middle;
    width: $titleWidth;
  }

  .Question--score {
    position: absolute;
    margin-left: $selectorsWidth + $padding + $extraMargin;
    left: $titleWidth;
    right: $linkWidth + $expandWidth;
    top: 50%;

    transform: translateY(-50%);
  }

  .Question--link {
    display: table-cell;
    vertical-align: middle;

    margin: 0 0 0 auto;
    width: $linkWidth;
    text-align: right;
  }

  .Question--expand {
    display: table-cell;
    vertical-align: middle;
    width: $expandWidth;
    height: 100%;
    padding: 0 0 0 20px;

    text-align: center;

    > .ico-back {
      transform: rotate(180deg);
      transition: transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
      stroke: $c03;
      vertical-align: middle;
    }

    &.Question--expanded .ico-back {
      transform: rotate(270deg);
    }

  }
}

.Question--score-value, .Question--label {
  display: inline-block;
  margin: 0 10px;

  font-weight: $fontBold;
  font-size: 14px;
}

.Question--score-value {
  width: 22px;
}

.Question--tick {
  display: inline-block;
  margin: 0 8px 0 0;
  cursor: pointer;
}

.Question--responses {
  margin: 0;
  padding: 20px;

  background-color: $alternativeBackgroundColor;
}

.Question--content {
  padding: 10px;

  p {
    margin: 0;
    padding-bottom: 3px;
  }
}