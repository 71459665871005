@import './_globals';
@import './_colors';

%form--label {
  font-size: 14px;
  line-height: 1.5;
  font-weight: $fontRegular;
  color: $c12;
}

.Form {
  margin: 40px 0 0;
}

.Form--error {
  margin: -40px 0 40px;
}

.Form--fieldset {
  margin: 0;
  padding: 0;

  width: 100%;

  > caption, > .Form--caption {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    text-align: left;

    margin-bottom: 8px;

    @extend %form--label;
  }
}

.Form--input {
  padding-right: 21px;

  > label {
    @extend %form--label;
    width: 100%;
    padding: 0 1px;
  }

  & > input,
  &.Form--fake > div,
  & .AutocompleteInput--text {
    // reset
    display: block;
    -webkit-appearance: none;
    box-sizing: border-box;
    background-image: none;
    background-clip: padding-box;
    border: none;
    width: 100%;

    padding: 7px 1px 10px;

    font-size: 16px;
    font-weight: $fontRegular;
    line-height: 1.5;
    background-color: transparent;
    color: $c03;

    border-bottom: solid 2px $c11;

    &:focus, &:hover {
      color: $c03;
    }

    &:focus {
      outline: 0;
    }
  }

  & .AutocompleteInput--text {
    cursor: pointer;
  }
}

.Form--actions {
  margin: 40px 0;
  padding: 30px 0 0;
  text-align: right;

  border: none;
  border-top: solid 1px $c11;
}

.Form--button {
  display: inline-block;
  -webkit-appearance: none;
  border: none;
  box-sizing: border-box;

  margin: 0 0 0 14px;
  padding: 0 20px;
  height: 44px;
  min-width: 136px;
  vertical-align: middle;

  font-size: 14px;
  font-weight: $fontBold;
  line-height: 44px;
  color: $c01;
  background: $c03;
  text-align: center;

  cursor: pointer;

  &:hover {
    background: $c02;
  }
}

.Form--cancel {
  display: inline-block;
  box-sizing: border-box;

  margin: 0 0 0 14px;
  padding: 0 20px;
  height: 44px;
  min-width: 136px;
  vertical-align: middle;

  font-weight: $fontRegular;
  color: $c03;
  line-height: 44px;
  text-align: center;

  cursor: pointer;

  &:hover {
    color: $c02;
  }
}

.Form--panel {
  background-color: $c11;
}