@import '../../utils/styles/_colors';
@import '../../utils/styles/_globals';

.Powerpoint-teams {
  width: 100%;
  min-height: 400px;
}

.Powerpoint--TeamContainer, .Powerpoint--SelectedTeamContainer {
  height: 550px;
  padding: 20px;
  overflow: scroll;
  border: 1px solid #CCC;
}

.Powerpoint--TeamContainer {
  margin-left: -5px;
  margin-right: 5px;
}

.Powerpoint--SelectedTeamContainer {
  margin-left: 5px;
  margin-right: -5px;
}

.Powerpoint--TeamBullet {
  margin: 8px 0 0;
  display: inline-block;

  border-radius: 15px;
  background-color: $c11;
}

.Powerpoint--TeamName {
  display: inline-block;
  padding: 0 20px;
}

.Powerpoint--TeamRemove {
  display: inline-block;
  cursor: pointer;
  padding: 0 8px;
  vertical-align: middle;
}

.Powerpoint--ToggleLabel {
  margin-top: 1em;
  font-size: 14px;
  color: $c03;

  cursor: pointer;
  vertical-align: middle;
}

.Powerpoint--Toggle {
  display: inline-block;

  margin: 0 0 0 10px;
  vertical-align: middle;
}

.Powerpoint--action {
  clear: both;

  padding: 20px 0 0;
  text-align: center;
}

.Powerpoint--options {
  clear: both;

  padding: 20px 0 0;
}