@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';

.ShareView--container {
  background-color: $c03;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  max-height: 50px;
  transition-property: all;
  transition-duration: .75s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  z-index: $zIndexOverlay + 1;

  &.hide {
    max-height: 0px;
  }
}

.ShareView--button {
  cursor: pointer;
  padding: 11px;
  border: 1px solid $c10;
  background-color: $c03;
  color: $c01;
  min-width: 300px;
  font-size: 14px;
  font-weight: $fontBold;
  line-height: 16px;
  outline: none;
}

.ShareView--notice {
  color: $c01;
}
