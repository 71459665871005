
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';

$color: #68778A;
$height: 242px;
$borderRadius: 1px;

.QuestionDetailsVertical {
  border-bottom: solid 2px $iconColor2;

  position: relative;
  padding: 0 50px 22px;
  background: $alternativeBackgroundColor;

  > svg {
    position: absolute;
    top: 0;
    left: 32px;
    height: 78px;
    width: 56px;
    fill: $iconColor2;
  }

  > h3 {
    margin: 0;
    padding: 7px 0 7px 52px;
    font-size: 14px;
    color: $c08;
    min-height: 48px;
  }

  &.QuestionDetailsVertical--noQuestions {
    padding-top: 2px;
    padding-bottom: 10px;

    > h3 {
      padding-left: 0;
      min-height: auto;
    }
  }
}

.QuestionDetailsVertical--indicator {
  background: transparent;

  position: relative;

  float: left;
  width: 20px;
  height: $height;
  margin: 20px 40px 0 0;
  border-radius: $borderRadius;

  > .QuestionDetailsVertical--current-indicator {
    position: absolute;
    left: 0;
    right: 0;

    text-indent: 32px;
    line-height: 2px;
    font-size: 14px;
    font-weight: $fontMedium;

    &:before {
      $height: 4px;
      $length: 7px;

      position: absolute;
      content: '';

      right: -$length - 2;
      top: -$height + 1;

      width: 0;
      height: 0;
      border-top: $height solid transparent;
      border-bottom: $height solid transparent;
      border-right: $length solid $c00;
    }
  }

  > .top, > .bot {
    position: absolute;
    right: 28px;

    font-size: 14px;
    font-weight: $fontMedium;
  }

  > .top {
    top: -6px;
    color: $score5;
  }

  > .bot {
    bottom: -3px;
    color: $score1;
  }

  > .part {
    box-sizing: border-box;

    margin-bottom: 2px;
    border-radius: $borderRadius;

    &.score1 {
      background: $score1;
      margin-bottom: 0;
    }

    &.score2 {
      background: $score2;
    }

    &.score3 {
      background: $score3;
    }

    &.score4 {
      background: $score4;
    }

    &.score5 {
      background: $score5;
    }
  }
}

.QuestionDetailsVertical--responses {
  box-sizing: border-box;
  margin: 20px 0 0 0;
  padding: 9px 0 6px 0;
  list-style: none;
  height: $height;

  font-size: 14px;
  color: $color;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > li {
    ul {
      padding-left: 10px;
      list-style-type: disc;
    }
    ul ul { list-style-type: circle; }
    ul ul ul { list-style-type: square; }
  }

  .QuestionDetailsVertical--showAll & {
    padding: 0;
  }
}