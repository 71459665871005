@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';
@import '../../../../utils/styles/_layout.scss';

$tileColor: $c12;
$tileShadowColor: #E5E7EC;
$selectedTileColor: $selectColor;

.Tiles {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -40px;
}

.TileRow {
  display: flex;
  justify-content: center;
}

.TileGroup > div {
      transition: margin-top 250ms;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      margin-bottom: 20px;
}

.TileGroup:hover {

  > div {
    margin-top: -10px;
  }
}

.Tile {
  padding: 5px 0 0 5px;
  cursor: pointer;

  & > div {
    width: 100px;
    height: 80px;

    border-radius: 4px;
    border: 1px solid $c11;
    margin: 1px;
    box-shadow: inset 280px 0 0 0 $alternativeBackgroundColor, 1px 20px 36px 0 $tileShadowColor;

    & > div {
      border-radius: 2px;
      height:80px;
      padding: 5px 5px 0 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .Tile--score {
    display: block;
    width: 100%;
    font-size: 34px;
    line-height: 36px;
    color: $tileColor;
    flex: 0 0 auto;
    order: 1;
  }

  .Tile--name {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    max-height: 36px;
    color: $tileColor;
    order: 4;
    overflow: hidden;
    margin-bottom: 4px;
  }

  .Tile--progress {
    display: block;
    order: 3;
    width: 100%;
    margin: 0 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 14px;
    font-weight: $fontRegular;
    color: $c12;

    > span {
      vertical-align: middle;
    }

    > svg {
      margin: 0 3px;
      fill: $c09;
      vertical-align: middle;
    }
  }

  .Tile--tooltip {
    opacity: 0;
    display: block;
    position: absolute;
  }
}

.Tile:hover .Tile--tooltip {
  opacity: 1;
  transition: opacity  0s linear 1.5s;
  display: block;
  position: absolute;
  height: 1.2em;
  width: auto;

  margin-top: -15px;
  margin-left: 5px;
  z-index: $zIndexForeground + 2;

  background: $backgroundColor;
  border: solid 1px $c10;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.21);

  font-size: 14px;
  font-weight: $fontRegular;
  color: $c09 !important;
}

.Tile--score5 {
  &.Tile--Parent > div { 
    border-width: 4px;
  }
  > div {
    margin: 0;
    border: 2px solid $score5Border;
  }
}

.Tile--score4 {
  &.Tile--Parent > div { 
    border-width: 4px;
  }
  > div {
    margin: 0;
    border: 2px solid $score4Border;
  }
}

.Tile--score3 {
  &.Tile--Parent > div { 
    border-width: 4px;
  }
  > div {
    margin: 0;
    border: 2px solid $score3Border;
  }
}

.Tile--score2 {
  &.Tile--Parent > div { 
    border-width: 4px;
  }
  > div {
    margin: 0;
    border: 2px solid $score2Border;
  }
}

.Tile--score1 {
  &.Tile--Parent > div { 
    border-width: 4px;
  }
  > div {
    margin: 0;
    border: 2px solid $score1Border;
  }
}

.Tile--colored.Tile--score5 > div {
  border: 1px solid $score5Border;
  box-shadow: inset 280px 0 0 0 $c41, 0 2px 20px 0 rgba(90,219,207,0.42), 1px 20px 36px 0 #9DD8C7;
  & > div {
    background-color: $score5;
  }

  .Tile--score, .Tile--name, .Tile--progress {
    color: $score5Text;
  }

  .Tile--score svg {
    fill: $score5Text;
  }

  .Tile--progress > svg {
    fill: $score5Text;
  }
}

.Tile--colored.Tile--score4 > div {
  border: 1px solid $score4Border;
  box-shadow: inset 280px 0 0 0 $c41, 0 2px 20px 0 #F9FAFC, 1px 20px 36px 0 rgba(176,220,172,0.5);
  & > div {
    background-color: $score4;
  }

  .Tile--score, .Tile--name, .Tile--progress {
    color: $score4Text;
  }

  .Tile--score svg {
    fill: $score4Text;
  }

  .Tile--progress > svg {
    fill: $score4Text;
  }
}

.Tile--colored.Tile--score3 > div {
  border: 1px solid $score3Border;
  box-shadow: inset 280px 0 0 0 $c41, 0 2px 20px 0 #F9FAFC, 1px 20px 36px 0 rgba(232,242,162,0.5);
  & > div {
    background-color: $score3;
  }

  .Tile--score, .Tile--name, .Tile--progress {
    color: $score3Text;
  }

  .Tile--score svg {
    fill: $score3Text;
  }

  .Tile--progress > svg {
    fill: $score3Text;
  }
}

.Tile--colored.Tile--score2 > div {
  border: 1px solid $score2Border;
  box-shadow: inset 280px 0 0 0 $c41, 0 2px 20px 0 #F9FAFC, 1px 20px 36px 0 rgba(245,120,81,0.5);
  &> div {
    background-color: $score2;
  }

  .Tile--score, .Tile--name, .Tile--progress {
    color: $score2Text;
  }

  .Tile--score svg {
    fill: $score2Text;
  }

  .Tile--progress > svg {
    fill: $score2Text;
  }
}

.Tile--colored.Tile--score1 > div {
  border: 1px solid $score1Border;
  box-shadow: inset 280px 0 0 0 $c41, 0 2px 20px 0 #F9FAFC, 1px 20px 36px 0 rgba(218,51,56,0.5);

  & > div {
    background-color: $score1;
  }

  .Tile--score, .Tile--name, .Tile--progress {
    color: $score1Text;
  }

  .Tile--score svg {
    fill: $score1Text;
  }

  .Tile--progress > svg {
    fill: $score1Text;
  }
}

.Tile--default {
  & > div > div {
    background-color: $c01;
  }
}

.Tile--selected > div {
  border: 2px solid $selectedTileColor;
  margin: 0;
  font-weight: $fontMedium;
}

.Tile--scoreNa {
  .Tile--progress > svg {
    stroke: $scoreNaProgress;
  }
}

.Tile--highlightProgress > div > div {
  &:before {
    display: block;
    content: '';
    flex: 1 1 0px;
    order: 2;
    max-height: 9px;

    width: 100%;
  }

  .Tile--name {
    max-height: 18px;
  }
}

.Tile--highlightProgress.Tile--score1 {
  .Tile--score {
    color: $score1Progress;
  }

  .Tile--progress > svg {
    fill: $score1Progress;
  }
}

.Tile--highlightProgress.Tile--score2 {
  .Tile--score {
    color: $score2Progress;
  }

  .Tile--progress > svg {
    fill: $score2Progress;
  }
}

.Tile--highlightProgress.Tile--score3 {
  .Tile--score {
    color: $score3Progress;
  }

  .Tile--progress > svg {
    fill: $score3Progress;
  }
}

.Tile--highlightProgress.Tile--score4 {
  .Tile--score {
    color: $score4Progress;
  }

  .Tile--progress > svg {
    fill: $score4Progress;
  }
}

.Tile--highlightProgress.Tile--score5 {
  .Tile--score {
    color: $score5Progress;
  }

  .Tile--progress > svg {
    fill: $score5Progress;
  }
}

.Tile--dimmed {
  opacity: 0.4;
}

.Tile--subTiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1400px) {
    display: none;
  }
 }