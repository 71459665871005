@import '../../../../utils/styles/_colors.scss';
@import '../../../../utils/styles/_globals.scss';

$childHeight: 45px;

.TeamList {
  padding-top: 16px;

  .TeamList--label .TeamList--share {
    display: none;

    svg {
      fill: $c03;
    }
  }

  .TeamList--label:hover .TeamList--share {
    display: inline-block;
  }

  .TeamList--organisation {
    margin-top: 15px;
    span {
      font-size: 16px;
    }
  }
}
