@import '../../../../utils/styles/_colors.scss';
@import '../../_constants.scss';

.TeamCells {
  height: 48px;
  white-space: nowrap;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &.hover {
    box-shadow: 1px 2px 11px -6px $c03;

    border-color: #EFF1F4;

    border-top-color: $heatmapRowHoverBorder;
    border-bottom-color: $heatmapRowHoverBorder;
    background: $heatmapEvenBackground;
      transition:
        box-shadow 0.3s ease-in-out,
        border-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
  }

  .TeamCells--Overall {
    display: inline-block;
    margin-right: 15px;
    margin-left: 15px;

    > div:last-child {
      margin-right: 0;
    }
  }

  > .TeamCells--Group3-Container {
    margin-right: 5px;
  }

  .TeamCells--Group {
    height: 48px;
    display: inline-block;
    margin-right: 4px;
    
    > div {
      margin-right: 1px;
    }

    > div:last-child {
      margin-right: 0;
    }
  }
}
