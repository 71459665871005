@import '_globals.scss';
@import '_colors.scss';

$TableFullHeaderFontSize: 14px;
$TableFullHeaderColor: $c08;
$TableFullHeaderActiveColor: $c03;
$TableFullHeaderHeight: 50px;
$TableFullHeaderBottomMargin: 8px;

$TableFullOddBackGround: #fff;
$TableFullRowHeight: 42px;
$TableFullRowHoverBorder: $c11;


%Table-full--table {
  border-spacing: 0 2px;
  width: 100%;
  margin-bottom: 36px;
}

%Table-full--head {
  position: relative;
  padding: 0 0 $TableFullHeaderBottomMargin 0;
  height: $TableFullHeaderHeight;

  background: linear-gradient($backgroundColor, #fff);
  border-right: solid 1px $c11;
  box-shadow: inset 0 -2px 0 0 $c11;

  > div {
    position: absolute;
    bottom: 0; left: 0; right: 0;
    padding: 0 1em;
    height: 35px;
    text-align: left;

    font-weight: $fontRegular;
    font-size: $TableFullHeaderFontSize;
    color: $TableFullHeaderColor;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  &.active > div {
    color: $TableFullHeaderActiveColor;
  }
}

%Table-full--body {
  > tr {
    cursor: pointer;

    > th, > td {
      box-sizing: border-box;
      height: $TableFullRowHeight;

      border-top: solid 1px $backgroundColor;
      border-bottom: solid 1px $backgroundColor;
      transition:
        border-color 0.3s ease-in-out,
        background 0.3s ease-in-out,
        color 0.3s ease-in-out;

      padding: 0 1em;
      text-align: left;
      color: $c08;

      // name
      &:first-child {
        border-left: solid 1px $backgroundColor;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      // whoever is last
      &:last-child {
        border-right: solid 1px $backgroundColor;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &:hover {
      transition: box-shadow 0.3s ease-in-out;
      box-shadow: 1px 2px 11px -8px $c03;

      > td, > th {
        border-color: $TableFullRowHoverBorder;
        background: $TableFullOddBackGround;
      }
    }

    &.highlighted {
      > td, > th {
        // box-shadow: 1px 2px 11px -8px $c03;
        background: #E7EEF9 !important;
      }
    }
  }

  &:not(.odd) {
    > tr:nth-child(even) {
      > td, > th {
        background: $TableFullOddBackGround;
      }
    }
  }

  &.odd {
    > tr:nth-child(odd) {
      > td, > th {
        background: $TableFullOddBackGround;
      }
    }
  }
}