@import './colors.scss';

.btn {
  -webkit-appearance: button;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:focus {
    outline: 0;
  }

  &.btn-primary {
    background-color: $c03;
    color: $c01;

    &:focus {
      box-shadow: 0 0 0 0.2rem transparentize($c03, 0.75)
    }

    &:hover {
      background-color: $c02;
    }
  }

  &.btn-secondary {
    background-color: #E2E2E2;
    color: #6A6A6A;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.05);
    }

    &:hover {
      background-color: darken(#E2E2E2, 5%)
    }
  }

  &.btn-rounded {
    color: white;
    font-size: 16px;
    height: 40px;
    min-width: 137px;
    border-radius: 40px;
    border: none;
    background-color: $c03;

    &:hover {
      background-color: $c02;
    }
  }
}