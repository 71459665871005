
@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_globals.scss';

$breadcrumbsHeight: 47px;

.Breadcrumbs {
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 1px 0 0 15px;
  list-style: none;

  height: $breadcrumbsHeight;

  > li {
    position: relative;
    margin: 0;
    padding: 0 15px 0 23px;
    flex: 0 4 auto;
    min-width: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > a {
      display: inline-block;
      height: $breadcrumbsHeight;
      line-height: $breadcrumbsHeight;
      font-size: 14px;
      color: $c08;

      &:hover {
        color: $c02;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 1px;
      width: 0;
      height: 0;
      border-left: solid 7px #EEF1F4;
      border-top: solid 24px transparent;
      border-bottom: solid 24px transparent;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-left: solid 7px #F9FAFC;
      border-top: solid 24px transparent;
      border-bottom: solid 24px transparent;
    }

    &:first-child {
      flex: 0 3 auto;

      padding-left: 0;

      &:before, &:after {
        display: none;
      }
    }

    &:last-child {
      flex: 0 1 auto;

      > a {
        font-weight: $fontBold;
        color: $c03;

        &:hover {
          color: $c02;
        }
      }
    }

    @media(max-width: 500px) {
      display: none;

      &:last-child {
        display: block;

        padding-left: 0;

        &:before, &:after {
          display: none;
        }
      }
    }
  }
}