@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_layout.scss';

$sidebarBorderColor: $c11;
$sidebarAlternativeLinkSize: 44px;

.Sidebar {
  position: fixed;
  width: $sidebarWidth;
  background-color: $backgroundColor;
  top: 98px;
  bottom: 0px;
  transition:
              left 0.6s ease-in-out,
              right 0.6s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: $zIndexMain + 1;
  border-top: solid 1px $sidebarBorderColor;

  &.left-hand {
    border-right: solid 1px $sidebarBorderColor;
    left: -$sidebarWidth;

    // sidebar open
    &.show {
      left: 0px;
    }
  }

  &.right-hand {
    border-left: solid 1px $sidebarBorderColor;

    right: -$sidebarWidth;

    // sidebar open
    &.show {
      right: 0px;
    }
  }

  @include media-medium-content {
    width: $sidebarWidthSmall;

    &.left-hand {
      left: -$sidebarWidthSmall;

      // sidebar open
      &.show {
        left: 0px;
      }
    }

    &.right-hand {
      right: -$sidebarWidthSmall;

      &.show {
        right: 0px;
      }
    }
  }

  @include media-sidebar-docked {
    // behaviour is opposite when docked on large screens
    &.left-hand {
      left: 0px;

      &.show {
        left: -$sidebarWidth;
      }
    }

    &.right-hand {
      right: 0px;

      &.show {
        right: -$sidebarWidth;
      }
    }
  }

  > div {
    overflow: hidden;
  }
}

.Sidebar--alternative-link {
  // reset button
  appearance: none;
  border: none;

  display: block;
  position: absolute;

  top: 20px;
  right: 16px;

  height: $sidebarAlternativeLinkSize;
  width: $sidebarAlternativeLinkSize;
  border-radius: 50%;

  background: $c03;
  color: $c01;
  transition: background-color 0.3s ease-in-out;

  text-align: center;
  cursor: pointer;

  &:hover {
    background: $c02;
  }

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    fill: currentColor;
  }
}