
@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_colors.scss';

$grouplistLeftMargin: 20px;

%active-state {
  background: linear-gradient(to right, $c03, $c03 4px, #fff 4px, transparent 110%);

  > a {
    color: $c03;
  }
}

.GroupsList {
  padding-top: 16px;
}

.GroupsList--Search {
  padding: 0 12px 0 $grouplistLeftMargin;
  margin: 18px 0 30px;

  > input {
    -webkit-appearance: none;
    box-sizing: border-box;

    width: 100%;
    height: 53px;
    border-radius: 26px;

    border: solid 1px transparent;
    background-color: #fff;
    box-shadow: inset 0 2px 3px 0 $c11;

    font-size: 14px;
    font-weight: $fontRegular;


    &:focus, &:hover {
      color: $c03;
    }
  }
}