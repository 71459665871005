@import "../../../../utils/styles/_globals.scss";
@import "../../../../utils/styles/_colors.scss";
@import "../../_constants.scss";

.Heatmap--Head {
  background-color: $backgroundColor;
  white-space: nowrap;

  .Heatmap--HeadHierarchy {
    display: flex;
    align-items: flex-end;
    height: 210px;

    > div {
      margin-left: 26px;
      margin-bottom: 6px;
    }

    a {
      font-weight: $fontBold;
    }
  }

  .SidebarCell span {
    font-weight: $fontBold;
  }

  .Heatmap--Head-overall {
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    width: 67px;
    height: 20px;
    vertical-align: top;

    span {
      font-size: 14px;
      color: $c12;

      display: inline-block;
      position: absolute;
      text-align: center;

      top: 10px;
      width: 67px;
    }
  }
}

.Heatmap--Head-Grouping3 {
  display: inline-flex;
  flex-direction: column;
  margin-right: 5px;
  margin-bottom: 10px;

  .Heatmap--Head-Title-Tooltip-Container {
    border-style: solid;
    border-width: 2px;
    border-radius: 6px;
    border-color: $c03;
    box-sizing: border-box;
    height: 100%;
    line-height: 24px;
    padding: 0 4px;
  }
}

.Heatmap--Head-Grouping3-Title {
  cursor: pointer;
  width: 100%;
  height: 30px;
  margin-bottom: 8px;
  color: $c03;
  overflow: visible;
  position: relative;
  box-sizing: border-box;
  white-space: nowrap;

  .Heatmap--Head-GroupName {
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
  }

  .Heatmap--Grouping3-Expand {
    position: absolute;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    border-color: $c03;
    width: 14px;
    height: 14px;
    border-radius: 6px;
    padding: 0;
    right: -4px;
    top: -6px;
    z-index: $zIndexForeground;
    background-color: $c01;
    :hover {
      background-color: $c03;
      path {
        fill: $c01;
      }
    }
    > svg {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &:hover {
    .Heatmap--Head-Title-Tooltip-Container {
      color: $c01;
      background-color: $c03;
    }
  }
}
.Heatmap--Head-Subgroup-Container {
  border-radius: 6px;
  overflow: visible;
  position: relative;
  height: 148px;
}

.Heatmap--Head-Grouping3-collapsed {
  display: inline-flex;
  width: 38px;
  margin-right: 3px;
  color: $c03;
  &:hover {
    color: $c01;
    background-color: $c03;
  }

  position: relative;
  top: 38px;
  border-style: solid;
  border-width: 2px;
  border-color: $c03;
  border-radius: 6px;
  box-sizing: border-box;
  height: 148px;
  .Heatmap--Head-Grouping3-Container-collapsed {
    width: 38px;
    padding-left: 4px;

    .Heatmap--Head-Grouping3-Container-collapsed-Title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 140px;
      padding: 6px;
    }
  }

  .rotated {
    width: 144px;
    transform: rotate(-90deg) translate(-100%);
    transform-origin: left top;
    text-align: left;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: normal;
    font-size: 12px;
  }
  .Heatmap--Head-Title-Tooltip {
    top: 40px;
  }
  .Heatmap--Grouping2-Expand {
    cursor: pointer;
    position: absolute;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    border-color: $c03;
    width: 14px;
    height: 14px;
    border-radius: 6px;
    padding: 0;
    right: -3px;
    top: -6px;
    z-index: $zIndexForeground;
    background-color: $c01;
    :hover {
      background-color: $c03;
      path {
        fill: $c01;
      }
    }
    > svg {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.Heatmap--Head-Title-Tooltip {
  opacity: 0;
  display: block;
  position: absolute;
}
.Heatmap--Head-Grouping3-Container-collapsed:hover .Heatmap--Head-Title-Tooltip,
.Heatmap--Head-Subgroup:hover .Heatmap--Head-Title-Tooltip,
.Heatmap--Head-Subgroups-Header:hover .Heatmap--Head-Title-Tooltip,
.Heatmap--Head-Subgroup-Container:hover > .Heatmap--Head-Title-Tooltip {
  display: block;
  position: absolute;
  opacity: 1;
  transition: opacity 0s linear 1s;

  margin-left: 5px;
  z-index: $zIndexForeground + 1;

  background: $backgroundColor;
  border: solid 1px $c10;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);

  font-size: 14px;
  font-weight: $fontRegular;
  color: $c09 !important;
}

.Heatmap--Head-Title-Tooltip-Container:hover > .Heatmap--Head-Title-Tooltip {
  display: block;
  position: absolute;
  top: 16px;
  opacity: 1;
  transition: opacity 0s linear 1s;

  margin-top: -16px;
  margin-left: 5px;
  z-index: $zIndexForeground + 1;
  text-align: center;

  background: $backgroundColor;
  border: solid 1px $c10;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);

  font-size: 14px;
  font-weight: $fontRegular;
  color: $c09;
}

.Heatmap--Head-Subgroup-Outer {
  all: unset;
  overflow: visible;
  position: relative;
}

.Heatmap--Head-Subgroup-Container:not(:first-child) {
  border-left-style: solid;
  border-left-color: white;
  border-left-width: 2px;
  box-sizing: border-box;
}

.Heatmap--Head-Subgroup-Container:first-child {
  margin-left: 2px;
}

.Heatmap--Head-Subgroups {
  height: 147px;
  overflow: visible;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .Heatmap--Head-Subgroup:hover > div {
    background: $c01;
    border-color: $c01;
    color: $heatmapHeadColorDark;
  }

  .Heatmap--Head-Subgroups-Header {
    height: 30px;
    padding: 0;
    display: flex;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: $c10;
    > .Heatmap--Head-Subgroup-Title {
      text-align: center;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px 4px;
      font-size: 12px;
      width: 100%;
    }
    > span:last-child {
      padding: 0 10px 0 0;
    }
    > .Heatmap--Head-Title-Tooltip {
      top: 20px;
      right: 20px;
    }
  }
  .Heatmap--Grouping2-Expand {
    position: absolute;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    border-color: $c03;
    width: 14px;
    height: 14px;
    border-radius: 6px;
    padding: 0;
    right: -3px;
    top: -6px;
    z-index: $zIndexForeground;
    background-color: $c01;
    :hover {
      background-color: $c03;
      path {
        fill: $c01;
      }
    }
    > svg {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .Heatmap--Head-Subgroup {
    display: inline-block;
    height: 118px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    width: 37px;
    max-width: 37px;
    margin-right: 1px;
    overflow: hidden;
    padding: 4px 0px;
    &:not(:first-child) {
      border-left-style: solid;
      border-left-color: white;
      border-left-width: 1px;
      box-sizing: border-box;
      margin-bottom: 8px;
    }

    &:last-child {
      margin-right: 0;
    }

    .Heatmap--Head-Title-Tooltip {
      top: 40px;
    }

    > .Heatmap--Head-Subgroup-name {
      transform: rotate(-90deg) translate(-100%);
      transform-origin: left top;
      text-align: left;
      width: $heatmapHeadHeight;
      height: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.noHeader {
        transform: rotate(-90deg) translate(-100%);
        width: 140px;
      }

      div {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 4px;
      }
    }
  }
}
.Heatmap--Head-maturity {
  height: 202px;
  padding-top: 8px;
}

.Heatmap--Head-maturity .Heatmap--Head-Subgroups {
  &.group-1 .Heatmap--Head-Subgroup-Container {
    background: $varA1;
    color: $heatmapHeadColorLight;
    path {
      fill: $heatmapHeadColorLight;
    }
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
      path {
        fill: $heatmapHeadColorDark;
      }
    }
  }

  &.group-2 .Heatmap--Head-Subgroup-Container {
    background: $varA2;
    color: $heatmapHeadColorLight;
    path {
      fill: $heatmapHeadColorLight;
    }
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
      path {
        fill: $heatmapHeadColorDark;
      }
    }
  }

  &.group-3 .Heatmap--Head-Subgroup-Container {
    background: $varA3;
    color: $heatmapHeadColorDark;
    path {
      fill: $heatmapHeadColorDark;
    }
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
    }
  }

  &.group-4 .Heatmap--Head-Subgroup-Container {
    background: $varA4;
    color: $heatmapHeadColorDark;
    path {
      fill: $heatmapHeadColorDark;
    }
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
    }
  }

  &.group-5 .Heatmap--Head-Subgroup-Container {
    background: $varA5;
    color: $heatmapHeadColorDark;
    path {
      fill: $heatmapHeadColorDark;
    }
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
    }
  }

  &.group-6 .Heatmap--Head-Subgroup-Container {
    background: $varA6;
    color: $heatmapHeadColorDark;
    path {
      fill: $heatmapHeadColorDark;
    }
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
    }
  }
}

.Heatmap--Head-outcome {
  height: 202px;
  padding-top: 8px;
}

.Heatmap--Head-outcome .Heatmap--Head-Subgroups {
  &.group-1 .Heatmap--Head-Subgroup-Container {
    background: $varB1;
    color: $heatmapHeadColorLight;
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
    }
  }

  &.group-2 .Heatmap--Head-Subgroup-Container {
    background: $varB2;
    color: $heatmapHeadColorLight;
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
    }
  }

  &.group-3 .Heatmap--Head-Subgroup-Container {
    background: $varB3;
    color: $heatmapHeadColorLight;
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
    }
  }

  &.group-4 .Heatmap--Head-Subgroup-Container {
    background: $varB4;
    color: $heatmapHeadColorLight;
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
    }
  }

  &.group-5 .Heatmap--Head-Subgroup-Container {
    background: $varB5;
    color: $heatmapHeadColorLight;
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
    }
  }

  &.group-6 .Heatmap--Head-Subgroup-Container {
    background: $varB6;
    color: $heatmapHeadColorDark;
    .Heatmap--Head-Subgroup:hover,
    .Heatmap--Head-Subgroups-Header:hover {
      background-color: $heatmapHeadColorLight;
      color: $heatmapHeadColorDark;
    }
  }
}
