@import '../../utils/styles/_colors';

.RadioSelect {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      margin: 0;
      padding: 0 0 0 12px;
      height: 44px;
    }

    .input {
      margin-right: 14px;
    }

    .pointer label {
      cursor: pointer;
    }

    label {
      color: $c08;
      font-size: 14px;
    }

    .radio-disabled {
      label {
        color: $c09;
      }
    }
  }
}