
@import '../../utils/styles/_colors.scss';

.Toggle {
  .react-toggle-track {
    height: 22px;
    width: 45px;

    background: $c09;
  }

  .react-toggle-thumb {
    top: 3px;
    left: 3px;

    height: 16px;
    width: 16px;
    border: none;

    box-shadow: 2px 2px 0 0 rgba(0,0,0,0.19);
  }

  .react-toggle-track-check {
    stroke: #fff;
    opacity: 0.5;

    width: 12px;
    height: 10px;
    left: 7px;
  }

  .react-toggle-track-x {
    stroke: #fff;
    opacity: 0.5;

    width: 9px;
    height: 9px;
    right: 9px;
  }

  &.react-toggle--checked {
    .react-toggle-track {
      background: $c03;
    }

    .react-toggle-thumb {
      left: 26px;
      box-shadow: 2px 2px 0 0 rgba(0,0,0,0.5);
    }
  }

  &.react-toggle:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background: $c08;
    }
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background: $c03;
    }
  }

}