@import '../../_constants.scss';

@keyframes heatmap-height-in {
  0% { height: 0; }
  100% { height: 48px; }
}

@keyframes heatmap-height-out {
  0% { height: 48px; }
  100% { height: 0; }
}

@keyframes heatmap-opacity-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.Animation-height {
  position: relative;
  height: 52px;
  animation: heatmap-height-in $heatmapAnimationDuration $heatmapAnimationTiming;

  &.Animation-height-out {
    animation: heatmap-height-out $heatmapAnimationDuration $heatmapAnimationTiming;
  }

  &.Animation-opacity-in > div {
    animation: heatmap-opacity-in 0.3s $heatmapAnimationTiming;
  }
}
