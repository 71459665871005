@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';

$animationColor1: #F6F7F8;
$animationColor2: #EEEEEE;
$animationColor3: #DDDDDD;

@keyframes placeHolderShimmer{
  0%{
      background-position: -35px 0
  }
  100%{
      background-position: 35px 0
  }
}

.Cell {
  user-select: none;
  width: 37px;
  height: 37px;
  margin-top: 5px;
  text-align: center;
  vertical-align: middle;

  span {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    font-weight: $fontMedium;
    border-radius: 5px;

    background: $c10;

    > svg {
      fill: $iconColor2;
      vertical-align: middle;
    }
  }

  &.score1 span {
    background: $score1;
    color: $score1Text;
  }

  &.score2 span {
    background: $score2;
    color: $score2Text;
  }

  &.score3 span {
    background: $score3;
    color: $score3Text;
  }

  &.score4 span {
    background: $score4;
    color: $score4Text;
  }

  &.score5 span {
    background: $score5;
    color: $score5Text;
  }

  &.scoreNotAsked span {
    cursor: not-allowed;
    @include not-asked-stripes;
  }

  &.loading span {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: $animationColor1;
    background: linear-gradient(to right, $animationColor2 5%, $animationColor3 50%, $animationColor2 100%);
    background-size: 35px 35px;
  }
}

.Cell--clickable {
  cursor: pointer;

  &:hover {
    > span {
      box-shadow: 0px 0px 6px -1px $c09;
    }
  }

  &.score1, &.score2, &.score4, &.score5 {
    &:hover {
      > span {
        box-shadow: 0px 0px 6px -1px $c08;
      }
    }
  }
}
