.Logo {
  display: block;
  cursor: pointer;
  height: 100%;

  > img {
    border: none;
    display: block;
    width: 38px;
    height: 100%;
  }
}
