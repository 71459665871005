
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_layout.scss';
@import '../../../../utils/styles/_colors.scss';

.SidebarIcon {
  position: relative;
  padding: 0 8px 0 0;

  width: 100%;
  height: 100%;

  color: $c03;
  cursor: pointer;

  &:hover {
    color: $c02;
  }

  svg {
    position: absolute;
    fill: currentColor;

    &:first-child {
      margin-top: -4px;
      top: 50%;
      left: 0;
    }

    &:last-child {
      transition: transform 200ms ease-in-out;
      margin-top: -7px;
      top: 50%;
      left: 16px;
      transform: rotate(180deg);
    }
  }

  &.isOpen > svg:last-child {
    transform: rotate(0);
  }
}

@include media-sidebar-docked {
  .SidebarIcon {
    > svg:last-child {
      transform: rotate(0);
    }

    &.isOpen > svg:last-child {
      transform: rotate(180deg);
    }
  }
}