@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_colors.scss';
@import '../../utils/styles/_legend.scss';

.TeamsList--actions {
  display: flex;
  justify-content: flex-end;

  margin: 10px 0 38px;
}

.TeamList--Legend {
  @extend %legend;

  h3 {
    @extend %legend--title;
  }
}

.TeamsList--Type {
  flex: 0 0 320px;
  width: 320px;

  text-align: right;

  > label {
    &:last-child {
      margin: 0 10px 0 35px;
    }

    color: $c08;
    font-size: 14px;
    font-weight: $fontRegular;
    cursor: pointer;

    > .input {
      margin: 0 12px;
    }
  }
}