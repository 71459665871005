// generic colors

$c01: #FFF;
$c03: #0A1EAA;

$c07: #D84F5A; // == $score1

$c08: #373E47;
$c09: #B2B9C3;
$c10: #EEF1F4;

$c12: #657488;

$c41: #F9F7F8;

$warning: rgba(168,82,5,0.1);
$warningText: #A85205;

// deprecated

$c02: #002960; // to be replaced by other hover color
$c11: $c10; // #EBECEE;
$c00: $c08;

// generics

$backgroundColor: #F9FAFC;
$alternativeBackgroundColor: $c41;
$selectColor: $c03;

$iconColor1: #C3C3C3;
$iconColor2: #D8D8D8;

// score colors

// background

$score1: $c07;
$score2: #F57851;
$score3: #E8F2A2;
$score4: #B0DCAC;
$score5: #6EC4AB;

// border

$score1Border: #C40D13;
$score2Border: #D84709;
$score3Border: #D0DA86;
$score4Border: #85BB81;
$score5Border: #3AB793;

// text

$score1Text: #FFF;
$score2Text: #FFF;
$score3Text: #76831B;
$score4Text: #456D41;
$score5Text: #FFF;

// progress

$score1Progress: #D84F5A;
$score2Progress: #F57851;
$score3Progress: #76831B;
$score4Progress: #85BB81;
$score5Progress: #6EC4AB;
$scoreNaProgress: $c09;

// quadrant and levers background colors

$varA0: #007FB3;
$varA1: #0096d1;
$varA2: #00ADEF;
$varA3: #33BDF2;
$varA4: #66CEF5;
$varA5: #99DEF9;
$varA6: #CCEFFC;

$varB0: #001E4B;
$varB1: #002966;
$varB2: #003481;
$varB3: #335D9A;
$varB4: #6685B3;
$varB5: #99AECD;
$varB6: #CCD6E6;

// scatter plot colors

$chartColor1: #DB0276;
$chartColor2: #BA6700;
$chartColor3: #6DD49D;
$chartColor4: #5F820A;
$chartColor5: #FEEC15;
$chartColor6: #274E06;
$chartColor7: #0298D9;
$chartColor8: #FE7F15;
$chartColor9: #FF00F5;
$chartColor10: #02DBC9;
$chartColor11: #046B9B;
$chartColor12: #A8066E;

$scatterPlotDotColorFill: rgba(55, 62, 71, 0.58); // #373E47
$scatterPlotBackgroundColor: #EEEFF4;
$scatterPlotLabelColor: #242B33;

$selectedRow: #E8F0FE;

@mixin not-asked-stripes {
  background: repeating-linear-gradient(
    -45deg,
    $c41,
    $c41 2px,
    $c10 2px,
    $c10 4px
  )
}