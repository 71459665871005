@keyframes alertSlideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Alert {
  border-width: 1px;
  border-style: solid;
  padding: 1rem;
  border-radius: 6px;
  animation: 1s ease-out 0s 1 alertSlideInFromTop;

  &.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}
