@import '../../../../utils/styles/_colors.scss';
@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_tables-full.scss';

$groupedTableRowHeight: 44px;
$groupedTableHeadHeight: 60px;

$groupedTableAnimationDuration: 0.6s;
$groupedTableAnimationEasing: ease-in-out;
$groupedTableAnimationOffset: 50px;
$groupedTableAnimationOffsetSecondary: 40px;

.TeamsList--container {
}

.TeamsList--head {
  position: relative;

  .TeamsList--back-to-top {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    margin: 2px 12px 0 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    font-size: 14px;
    font-weight: $fontRegular;
    color: $c03;
    cursor: pointer;

    transition: opacity ease-in-out 0.2s;

    > span {
      vertical-align: middle;

      @media (max-width: 800px) {
        display: none;
      }
    }

    &.parents2 > span {
      display: none;
    }

    > svg {
      margin: 0 0 0 10px;
      vertical-align: middle;
      fill: currentColor;
    }

    &:hover {
      color: $c02;
    }

    &.visible {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.TeamsList--main {
  $remainingSpace: $headerHeight + $headerMenuHeight + $groupedTableHeadHeight + $footerHeight;
  height: calc(100vh - #{$remainingSpace});
  max-height: calc(100vh - #{$remainingSpace});
  overflow-y: auto;
  overflow-x: hidden;
}

.TeamsList--GroupedTable {
  position: relative;
  @extend %Table-full--table;
  margin-bottom: 0;

  .pointer {
    cursor: pointer;
  }

  thead tr {
    display: flex;
  }

  thead th {
    @extend %Table-full--head;
    display: block;

    .GroupedTable--SortIcon {
      fill: $c08;
    }

    &.active {
      .GroupedTable--SortIcon {
        fill: $c03;
      }
    }
  }

  tbody {
    @extend %Table-full--body;
    position: absolute;
    top: 0;
    width: 100%;

    opacity: 0;
    z-index: 0;
    visibility: hidden;

    transition:
      opacity $groupedTableAnimationDuration $groupedTableAnimationEasing,
      visibility 0s $groupedTableAnimationDuration $groupedTableAnimationEasing;
    animation: grouped-table-down-fade-out $groupedTableAnimationDuration $groupedTableAnimationEasing;

    &.selected {
      opacity: 1;
      z-index: 1;
      visibility: visible;

      transition: opacity $groupedTableAnimationDuration $groupedTableAnimationEasing;
      animation: grouped-table-down-fade-in $groupedTableAnimationDuration $groupedTableAnimationEasing;
    }

    &.still {
      animation: none;
    }

    &.still.selected {
      animation: none;
    }

    &.selected.backward {
      th > div {
        animation: grouped-table-up-selector $groupedTableAnimationDuration $groupedTableAnimationEasing;
      }
   }

    &.backward {
      animation-name: grouped-table-up-fade-out;
    }

    &.backward.selected {
      animation-name: grouped-table-up-fade-in;
    }

    &.level1 {
      top: $groupedTableRowHeight;
    }

    &.level2 {
      top: $groupedTableRowHeight * 2;
    }

    &.level3 {
      top: $groupedTableRowHeight * 3;
    }

    &.level4 {
      top: $groupedTableRowHeight * 4;
    }

    > tr {
      margin: 2px 0;
      display: flex;
      width: 100%;

      &:first-child {
        margin-top: 1px;
      }

      > td, > th {
        color: $c12;
        height: $groupedTableRowHeight - 2;
        line-height: $groupedTableRowHeight - 2;

        &:first-child {
          position: relative;
          font-weight: $fontRegular;

          > div {
            font-size: 14px;
            position: absolute;
            top: 0; left: 0; right: 0;
            height: $groupedTableRowHeight - 4;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
          }
        }

        // parent teams
        &:nth-child(3), &:nth-child(4) {
          font-size: 14px;
          font-weight: $fontRegular;
          color: $c12;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          > span.score {
            font-weight: $fontBold;
          }
        }

        > .score {
          display: inline-block;
          vertical-align: middle;
          width: 28px;
          text-align: right;
          font-weight: $fontBold;
        }

        > .scale {
          display: inline-block;
          vertical-align: middle;
        }

        > .change {
          display: inline-block;
          vertical-align: middle;

          margin: 0 4px;
          width: 6px;
          fill: $c09;

          text-align: center;

          > svg {
            margin: 0 0 2px;
            width:  6px;
            height: 6px;
          }

          &.increase {
            transform: scaleY(-1);
          }
        }

        > .ProgressScore {
          margin-top: 1px;
        }
      }

      > th {
        padding-left: 0;

        > div {
          position: relative;
          height: 100%;
          padding-left: 38px;

          &.withoutIcon {
            padding-left: 1em;
          }
        }

        .ico-parent, .ico-child, .ico-back, .ico-home, .ico-score-na {
          position: absolute;
          top: $groupedTableRowHeight/2;
          left: 1em;
        }

        .ico-child {
          top: ($groupedTableRowHeight/2) + 4;
          margin-top: -17px;
          fill: $c09;
        }

        .ico-parent {
          margin-top: -9px;
          transform: rotate(180deg);
          stroke: $c09;
        }

        .ico-back {
          margin-top: -9px;
          stroke: $c09;
        }

        .ico-home {
          margin-top: -9px;
          stroke: $c09;
        }

        .ico-score-na {
          margin-left: 2px;
          margin-top: -3px;
          fill: $c09;
        }

        &.tabbed > div {
          padding-left: 45px;

          .ico-parent {
            left: 1.5em;
          }
        }

        .link {
          display: none;
          position: absolute;
          height: 100%;
          right: 0;
          top: 0;

          padding: 0 10px;
          cursor: pointer;
        }

        &:hover .link {
          display: block;
        }
      }


      &.highlighted {
        > td, > th {
          font-weight: $fontBold;
          color: $c03;
        }
      }

      &.benchmark {
        cursor: initial;
        > td, > th {
          background: $selectedRow !important;
        }
      }
    }
  }
}

// columns sizing
.TeamsList--GroupedTable {
  thead > tr > th, tbody > tr > th, tbody > tr > td {
    flex: 1 1 50%;
    width: 50%;

    &:nth-child(2) {
      min-width: 250px;
    }
  }
}

.TeamsList--GroupedTable.parents1 {
  thead > tr > th, tbody > tr > th, tbody > tr > td {
    flex: 0 1 33%;
    width: 33%;

    &:first-child {
      flex: 1 0 34%;
      width: 34%;
      min-width: 34%;
      max-width: 34%;
    }

    &:nth-child(2) {
      min-width: 250px;
    }
  }
}

.TeamsList--GroupedTable.parents2 {
  thead > tr > th, tbody > tr > th, tbody > tr > td {
    flex: 0 1 22%;
    width: 22%;

    &:first-child {
      flex: 1 0 34%;
      width: 34%;
      min-width: auto;
      max-width: none;
    }

    &:nth-child(2) {
      min-width: 250px;
    }
  }
}

.TeamList--GroupedTable--actions {
  padding: 35px 0;
  display: flex;
  justify-content: center;

  > div {
    width: 276px;
    height: 44px;
    line-height: 44px;
    cursor: pointer;

    flex: 0 0 276px;

    background-color: $c03;
    text-align: center;
    color: $c01;
    font-weight: 14px;
    font-weight: $fontBold;

    &:hover {
      background-color: $c02;
    }
  }
}

@keyframes grouped-table-down-fade-in {
  0% {
    transform: translate3d($groupedTableAnimationOffset, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes grouped-table-down-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(-$groupedTableAnimationOffset, 0, 0);
    opacity: 0;
  }
}

@keyframes grouped-table-up-fade-in {
  0% {
    transform: translate3d(-$groupedTableAnimationOffset, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes grouped-table-up-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d($groupedTableAnimationOffset, 0, 0);
    opacity: 0;
  }
}

@keyframes grouped-table-up-selector {
  0% {
    transform: translate3d(-$groupedTableAnimationOffsetSecondary, 0, 0);
  }

  100% {
    transform: translate3d(0, 0 , 0);
  }
}