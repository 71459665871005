@import '../../../../utils/styles/_globals.scss';
@import '../../../../utils/styles/_colors.scss';

$selectedCardColor: $selectColor;
$hoverColor: $c09;
$subtitleColor: $c08;
$arrowColor: #EFF1F4;

.QuestionCard {
  border: 2px solid $c10;
  box-shadow: inset -8px -78px 0 0 $c01, 0 2px 0 0 $c11;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 600px;
  color: $c00;

  .QuestionCard--Details {
    width: 60%
  }

  .QuestionCard--Score {
    width: 30%
  }

  &:hover {
    cursor: pointer;
    border: 2px solid $hoverColor;
  }

  &.QuestionCard--selected, &.QuestionCard--selected:hover {
    border: 2px solid $selectedCardColor;
  }

  & > div:first-child {
    background-color: $c01;
    border-radius: 4px;
    padding: 10px;
  }

  .QuestionCard--tick {
    display: inline-block;
    margin-right: 15px;
  }

  .QuestionCard--cell {
    display: flex;
    height: 42px;
    align-items: center;
  }

  .QuestionCard--link {
    justify-content: flex-end;
    width: 10%;
  }

  .QuestionCard--title {
    font-weight: $fontBold;
    font-size: 15px;
  }

  .QuestionCard--subtitle {
    color: $subtitleColor;
    font-size: 12px;
    line-height: 24px;
  }

  .QuestionCard--score, .QuestionCard--label {
    min-width: 26px;
    margin: 0 4px;
    font-weight: $fontBold;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .QuestionCard--arrow {
    color: $arrowColor;
    display: inline-block;
    margin: 0 5px;
  }

  .QuestionCard--expand {
    display: flex;
    cursor: pointer;

    &.QuestionCard--expanded {
      .ico-back {
        transform: rotate(270deg);
      }
    }
  }

  .QuestionCard--expansion p {
    padding: 10px;
    margin: 0;
  }

  .ico-child {
    display: inline-block;
    fill: $iconColor2;
  }

  .ico-back {
    display: inline-block;
    stroke: $iconColor1;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
    margin-top: 5px;
    margin-right: 10px;
  }
}
