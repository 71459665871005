@import '../../../../utils/styles/_colors.scss';

.Questions {
  background-color: $c01;

  ul.Questions--list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      border-bottom: 1px solid $c10;

      &::last-child {
        border-bottom: none;
      }
    }
  }
}
