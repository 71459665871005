.EmptyScene {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .EmptyScene--heading {
    font-size: 24px;
    line-height: 28px;
  }

  .EmptyScene--subject, .EmptyScene--subject a {
    font-size: 18px;
    line-height: 21px;
  }
}