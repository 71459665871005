@import '_colors.scss';
@import '_globals.scss';

%legend {
  padding: 16px 16px 0;
}

%legend--title {
  margin: 0 0 20px 0;
  padding: 0;

  color: $c08;
  font-size: 14px;
  font-weight: $fontRegular;
}

%legend--line {
  margin: 16px -16px;
  border-top: 1px solid $c11;
  border-bottom: none;
}