@import '../../utils/styles/_globals.scss';
@import '../../utils/styles/_layout.scss';

.App-container {
  @include content-width;
  box-sizing: border-box;
  padding: 0 0 $footerMargin;

  &.no-footer-margin {
    padding-bottom: 0;
  }
}