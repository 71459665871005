@import '../../utils/styles/_colors';

$radioSize: 20px;
$radioCheckSize: 10px;
$radioBorderSize: 2px;

.Radio {
  position: relative;
  display: inline-block;
  height: $radioSize;
  width: $radioSize;
  overflow: hidden;
  vertical-align: middle;

  cursor: pointer;

  > input {
    position: absolute;
    top: 0; left: 0;
    visibility: hidden;
  }

  > label {
    box-sizing: border-box;
    position: absolute;
    top: 0; left: 0;
    height: $radioSize;
    width: $radioSize;

    border-radius: 50%;

    cursor: pointer;

    background: $c01;
    border: $radioBorderSize solid $c10;

    &:before {
      content: '';
      position: absolute;

      top: ($radioSize - 2 * $radioBorderSize - $radioCheckSize) / 2;
      left: ($radioSize - 2 * $radioBorderSize - $radioCheckSize) / 2;

      height: $radioCheckSize;
      width: $radioCheckSize;

      border-radius: 50%;
      background: $c03;

      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
  }

  > input:checked + label {
    &:before {
      content: '';
      opacity: 1;
    }
  }
}